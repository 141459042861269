<template>
  <div :class="chooseCoupon ? 'coupon-list popup-list' : 'coupon-list'" >
    <div @click="chooseCoupon && chooseCoupon(coupon)" :class="couponId === coupon._id ? 'active coupon-item':'coupon-item'" v-for="coupon in list" :key="coupon._id">
      <div>
        <span class="coupon-name">ID: {{ coupon.code_id }}</span>
        <span v-if="coupon.type === 'time'" class="coupon-price">{{ coupon.months }}个月免费体验</span>
        <span v-if="coupon.type === 'price'" class="coupon-price">抵扣{{ coupon.price }}元</span>
        <span v-if="coupon.type === 'discount'" class="coupon-price">{{ coupon.discount * 10}}折</span>
      </div>
      <span class="coupon-i">绑定时间：{{ getDate(coupon.boundAt) }}</span>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'coupon',
  data() {
    return {
      activeIndex: null,
      list: []
    }
  },
  props: ['couponId', 'chooseCoupon'],
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getCoupons()
  },
  methods: {
    getDate(time) {
      if (!time) {
        return ''
      }
      return moment(time).format('YYYY-MM-DD HH:mm')
    },
    getCoupons () {
      this.$store.httpDispatch({})('getCoupons')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.list
        }
      })
    }
  }
}
</script>

<style lang="less">
.popup-list {
  height: 80vh;
  overflow-y: auto;
  padding-top: 30px!important;
  padding-bottom: 100px!important;
}
.coupon-list {
  padding: 20px 20px 0;
  width: 100%;
  box-sizing: border-box;
  .coupon-item {
    width: 100%;
    margin-bottom: 20px;;
    border: 1px solid #eee;
    background: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    display: block;
    border-radius: 10px;
    .coupon-name {
      display: block;
      font-size: 22px;
      color: #756F79;
      font-weight: 600;
    }
    .coupon-price {
      display: block;
      margin: 5px 0;
      color: #007EC5;
      font-size: 30px;
      font-weight: 900;
    }
    .coupon-i {
      font-size: 15px;
      color: #555;
    }

  }
  .active {
    border: 1px solid #007EC5;
    background: #e4f5ff;
  }
}
</style>


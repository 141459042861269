<template>
  <div class="bind-phone-page">
    <!-- <img class="logo-img" alt="Vue logo" src="@/assets/logo.png"> -->
     <h1 class="logo-h1">
       低强度单波长红光治疗及控制青少年近视
     </h1>
    <div class="form-box" :style="`margin-top:${marginTop}px`">
      <div class="bind-phone-input" >
        <label class="input-label" for="idCode"><img style="width: 25px" src="../../assets/icon_from.png" /></label>
        <select v-model="fromState">
          <option :value="state" v-for="state in froms" :key="state">{{state}}</option>
        </select>
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="parentName"><img style="width: 25px" src="../../assets/icon_card.png" /></label>
        <input @focus="active=true" @blur="active=false" placeholder="监护人姓名" id="parentName" type="text" v-model="parentName" />
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="parentIdCode"><img style="width: 25px" src="../../assets/icon_card.png" /></label>
        <input @focus="active=true" @blur="active=false" placeholder="监护人身份证号" id="parentIdCode" type="text" v-model="parentIdCode" />
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="idCode"><img style="width: 25px" src="../../assets/icon_card.png" /></label>
        <input @focus="active=true" @blur="active=false" placeholder="使用者身份证号" id="idCode" type="text" v-model="idCode" />
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="mobile"><img src="../../assets/icon_phone.png" /></label>
        <input @focus="active=true" @blur="active=false" maxlength="11" placeholder="手机号" id="mobile" type="tel" v-model="mobile" />
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="smsCode"><img style="width: 20px" src="../../assets/icon_code.png" /></label>
        <input @focus="active=true" @blur="active=false" maxlength="6" placeholder="验证码" id="smsCode" type="tel" v-model="smsCode" />
        <mt-button class="code-btn" @click="sendSMS" @disabled="TIME_COUNT !== 60" plain type="primary">{{ TIME_COUNT !== 60 ? TIME_COUNT : '获取验证码' }}</mt-button>
      </div>
      <!--<div class="bind-phone-switch">
        <label>现实世界科学研究：</label>
        <mt-switch v-model="isRealDiscuss"></mt-switch>
      </div>-->
      <mt-button class="bind-phone-btn" @click="bindMobile" type="primary">确定绑定</mt-button>
      <p>登录注册表示同意用户协议、隐私条款</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'mint-ui'
const config = window.config
export default {
  name: 'bind-phone-page',
  data() {
    return {
      froms: [
        '中山眼科中心',
        '项目合作医院',
        '朋友推荐',
        '医生病人推荐',
        '医生推荐',
        '其他'
      ],
      fromState: '中山眼科中心',
      mobile: '',
      parentIdCode: '',
      parentName: '',
      idCode: '',
      smsCode: '',
      isRealDiscuss: false,
      timer: null,
      TIME_COUNT: 60,
      marginTop: 300
    }
  },
  mounted () {
    this.marginTop = document.documentElement.clientHeight * 0.4
    this.getCustomers()
  },
  methods: {
    sendSMS () {
      const setTimer = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.TIME_COUNT > 0) {
            this.TIME_COUNT--
            setTimer()
          } else {
            this.TIME_COUNT = 60
          }
        }, 1000)
      }
      if (!this.mobile) {
        return Toast({
          message: '请填写手机号',
          duration: 3000
        })
      }
      if (this.TIME_COUNT !== 60) {
        return Toast({
          message: `请在${this.TIME_COUNT}秒后重新发送`,
          duration: 3000
        })
      }
      this.TIME_COUNT--
      setTimer()
      this.$store.httpDispatch({})('sendSMS', {
        mobile: this.mobile
      })
      .then((res) => {
        if (res.data.code === 0) {
          Toast({
            message: '发送成功',
            duration: 3000
          })
        }
      })
      .catch((err) => {
        Toast({
          message: `${err.data.code}: ${err.data.msg}`,
          duration: 5000
        })
      })
    },
    getCustomers () {
      this.$store.httpDispatch({})('getSetting')
      .then((res) => {
        if (res.data.code === 0) {
          this.froms = res.data.data.setting.list
          this.fromState = this.froms[0] || ''
        }
      })
    },
    bindMobile () {
      if (!this.mobile) {
        return Toast({
          message: '请填写手机号',
          duration: 3000
        })
      }
      if (!this.smsCode) {
        return Toast({
          message: '请填写验证码',
          duration: 3000
        })
      }
      if (!this.parentName) {
        return Toast({
          message: '请填写监护人姓名',
          duration: 3000
        })
      }
      if (!this.parentIdCode) {
        return Toast({
          message: '请填写监护人身份证号码',
          duration: 3000
        })
      }
      if (!this.idCode) {
        return Toast({
          message: '请填写身份证号码',
          duration: 3000
        })
      }
      if (!this.fromState) {
        return Toast({
          message: '请选择入组来源',
          duration: 3000
        })
      }
      this.$store.httpDispatch({})('bindMobile', {
        mobile: this.mobile,
        smsCode: this.smsCode,
        idCode: this.idCode,
        parentIdCode: this.parentIdCode,
        parentName: this.parentName,
        isRealDiscuss: this.isRealDiscuss,
        channel: this.fromState,
        fromPlatform: config.fromPlatform
      }).then((res) => {
        if (res.data.code === 0) {
          this.$store.httpDispatch({})('getUserInfo').then((res) => {
            const { code, data } = res.data
            if (code === 0) {
              this.$store.dispatch('setUser', { user: data.user })
              this.$router.push('/')
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      })
      .catch((err) => {
        Toast({
          message: `${err.data.code}: ${err.data.msg}`,
          duration: 5000
        })
      })
    }
  }
}
</script>
<style lang="less">
.bind-phone-page {
  box-sizing: border-box;
  padding: 0 40px 0;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/bind_phone_background.png');
  background-size: cover;
  p {
    color: #54A7D8;
    // position: fixed;
    // bottom: 5px;
    left: 0;
    right: 0;
    margin: 35px 0 0 0;
  }
  .logo-img {
    width: 100%;
    /* margin-top: 30px; */
    position: relative;
    transform: scale(1.2);
    top: 15vh;
  }
  .logo-h1 {
    position: relative;
    top: 15vh;
    color: #F29F19;
    font-weight: 500;
    font-size: 32px;
    margin: 0 auto 0;
    height: 180px;
  }
  .bind-phone-switch{
    background: none;
    color:#333;
    text-align: left;
    padding: 15px 0;
    border-bottom: 1px solid #198ACB;
    label {
      display: inline-block;
    }
    .mint-switch {
      float: right;
    }

  }
  .bind-phone-btn {
    margin-top: 30px;
    height: 35px;
    line-height: 35px;
    width: 100%;
    background: #fff;
    color: #007FC2;
    border-radius: 100px;
    font-weight: 900;
  }
  .form-box {
    .bind-phone-input {
      width: 100%;
      border-bottom: 1px solid #198ACB;
      color: #F29F19;
      text-align: left;
      display: flex;
      vertical-align: bottom;
      padding-top: 15px;
      .input-label {
        width: 20px;
      }
      img {
        width: 18px;
      }
      input {
        letter-spacing: 2px;
        flex: 1;
        width: 100px;
        background: none;
        font-size: 16px;
        padding-left: 10px;
        height: 35px;
        color: #F29F19;
        position: relative;
        bottom: 5px;
        border: 0;
      }
      select {
        background:  none;
        border: none;
        width: 100%;
        color: #F29F19;
        padding-left: 10px;
      }
      input::-webkit-input-placeholder {
        color: #F29F19;
      }
      input::-moz-input-placeholder {
        color: #F29F19;
      }
      input::-ms-input-placeholder {
        color: #F29F19;
      }
      .code-btn {
        position: relative;
        top: 5px;
        line-height: 25px;
        height: min-content;
        background: #fff;
        color: #007FC2;
        border-radius: 100px;
        font-size: 13px;
        font-weight: 900;
        padding: 0 10px;
      }
    }
  }
}
</style>

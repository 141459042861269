

<template>
<div>
  <div class="report" :id="ruleForm._id" style="  ">
    <img src="@/assets/logo_2.png" style="width: 200px;margin-top: 20 0;" alt="">
    <h3 style="width: 220px;float:right;">编号：{{ruleForm.code}}</h3>
    <h1 style="margin-top:50px;text-align: center;color: #2889CF;font-size: 35px;">承诺函</h1>
    <p>
      使用人姓名：<span class="underline">{{ruleForm.usename || '    '}}</span> 身份证号码：<span class="underline">{{ruleForm.useIdCode}}</span>
    </p>
    <p>
      监护人姓名：<span class="underline">{{ruleForm.name}}</span> 登录账户：<span class="underline">{{ruleForm.username}}</span>
    </p>
    <p>
      联系地址：<span class="underline">{{ruleForm.address}}</span>
    </p>
    <br>
    <p>
      业务点名称： <span class="underline"> 艾尔兴广东服务中心 </span>
    </p>
    <p>
      联系方式： <span class="underline">020-87600696</span>
    </p>
    <p>
      本人于<span class="underline">{{getDate(ruleForm.payDate)}}</span>支付<span class="underline">{{ruleForm.guarantee}}</span>元押金用于租借一台艾尔兴仪器(编号<span class="underline">{{ruleForm.deviceCode}}</span>)。
    </p>
    <p>
     该押金于本人续费艾尔兴5年6个月特惠套餐时已抵扣，设备到期后，业务点无需再退还押金，套餐开始日期<span class="underline">{{getDate(ruleForm.startDate)}}</span>，套餐结束日期<span class="underline">{{getDate(ruleForm.endDate)}}</span>。
    </p>
    <br>
    <p>本人承诺：套餐到期后，一周内将无押金借用的仪器归还给租借仪器艾尔兴业务点，如到期未归还，将承担相应的法律责任。
    </p>
    <p>
      本人明确： <span class="underline" style="text-align: left">艾尔兴5年6个月套餐只针对艾尔兴老用户续费办理，为特惠套餐，用户办理后如提前结束仪器使用，剩余时间不做折算，不做退款!</span>
    </p>
    <div style="margin-top: 30px">
      <div style="display:inline-block;width:50%;position: relative">
        <div style="height: 40px">
        业务点签章(签字):
        </div>
        <div style="margin-top: 30px">
          广州明煜视觉科技有限公司
        </div>
         <img   style="position:absolute;width: 50%;top: 10px;left: 15%;" alt="Vue logo" src="@/assets/printsign.png">
      </div>
      <div style="display:inline-block;width:50%">
        <div style="height: 40px">
          <span>
             监护人签字：
          </span>
          <span>
            <img  v-if="ruleForm.signatureUrl" style="height: 40px;vertical-align: middle"
            crossOrigin="Anonymous"
            :src="ruleForm.signatureUrl + '?_now_='+new Date().getTime()" alt="">
          </span>
        </div>
        <div style="margin-top: 30px"  v-if="ruleForm.signatureUrl">
          签字日期: <span class="underline">{{getDate(ruleForm.signAt)}}</span>
        </div>
        <div style="margin-top: 30px" v-else>
          签字日期:
        </div>
      </div>
    </div>
    <p>
    </p>
  </div>
</div>
</template>
<script>
import moment from 'moment'
import html2canvas from 'html2canvas'
import oss from '../../utils/oss'
export default {
  props: ['ruleForm', 'print'],
  data() {
    return  {
      dataUrl: ''
    }
  },
  beforeMount () {
  },
  methods: {
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async uploadBlobToOSS (imgBase64) {
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
      const id = new Date().getTime()
      const blob = this.dataURLtoBlob(imgBase64)
      try {
        const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
        this.uploadLoading = false
        this.$store.httpDispatch({})('saveAgreement', {
          _id: this.ruleForm._id,
          imageUrl: result.url
        }).then((res) => {
          const { code } = res.data
          this.uploadLoading = false
        }).catch((err) => {
          console.log(err.data)
          this.uploadLoading = false
        })
      } catch (err) {
        this.uploadLoading = false
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
    },
    getDate (createdAt) {
      if (!createdAt) {
        return '/'
      }
      return moment(createdAt).format('YYYY年MM月DD日')
    }
  }
}
</script>
<style lang="less" scoped>
@media (max-width: 767px) {
  .report {
      padding: 5px 10px!important;
  }


}
.report {
  padding: 50px 30px 100px;
  .underline {
    font-weight: 800;
    text-align: center;
    border-bottom: 1px solid #777;
    display: inline-block;
    min-width:50px;
  }
          p {
            font-size: 13px;
            margin: 5px 0;
            line-height: 22px;
          }
          h3 {
            font-size: 15px;
            margin: 7px 0;
          }
  table td
  {
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid black;
  }
  footer {
    text-align: center;
    width: 100%;
  }
}
</style>
<template>
  <div >
    <div :style="{'top': top + 'px'}" id="canvas_body" v-show="activeKey">
      <canvas :width="width" :height="height" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd" id="canvas"></canvas>
      <mt-button @click="cancel" id="saveCanvas">取消</mt-button>
      <mt-button @click="clearRect" id="clearCanvas">清除</mt-button>
      <mt-button type="primary" @click="saveImg" :disabled="uploadLoading" id="saveCanvas">保存</mt-button>
    </div>
    <div v-show="!activeKey" class="consent-body">
      <div>
        <div class="info-page">
          <h1>低强度单波长红光控制青少年近视疗效的</h1>
          <h1>前瞻性多中心真实世界研究</h1>
          <div style="text-align: left;margin-bottom: 20px;">
            <span>检查日期: <span style="text-decoration: underline">{{getDate(info.createdAt)}}</span></span>
          </div>
          <div class="section-box">
            <section class="section">
              <div class="section-titile">
                <label>A.登记</label>
                <span>登记者编号 </span>
                <div class="input-div border-input small-input" style="text-align: left;width: 50px">{{info.userCode}}</div>
              </div>
              <div class="input-box">
                <label>受检者 ID：</label>
                <div class="input-div border-input" style="min-width: 100px;height: 20px">{{info.id}}</div>
              </div>
              <div>
                <div class="input-box inline-box">
                  <label>姓名：</label>
                  <div class="input-div inline-div">{{info.name}}</div>
                </div>
                <div class="input-box inline-box">
                  <label>出生日期：</label>
                  <div class="input-div inline-div">{{getDate(info.birthday)}}</div>
                </div>
              </div>
              <div class="input-box">
                <label>性别</label>
                <div class="small-input">{{info.gender === 'male' ? 0 : 1}}</div>
                <span>(0. 男； 1. 女)</span>
              </div>
              <div class="input-box">
                <label>A1. 父母有无高度近视？</label>
                <div class="small-input">{{info.parentIsNearsighted ? 1 : 0}}</div>
                <p>0. 否</p>
                <div>1. 有，具体情况<div class="input-div">{{info.parentNearsightedText}}</div></div>
              </div>
              <div class="input-box">
                <label>A2. 是否佩戴 OK 镜，第一次佩戴 OK 镜的时间？</label>
                <br /><div class="input-div">{{info.isOK}}</div>
              </div>
              <div class="input-box">
                <label>A3. 既往佩戴 OK 镜的使用频率，复查效果?(AL 增长 mm/time)</label>
                <br /><div class="input-div">{{info.OKText}}</div>
              </div>
              <div class="input-box">
                <label>A4. 是否使用阿托品，第一次使用阿托品的时间?</label>
                <br /><div class="input-div">{{info.isAtropine}}</div>
              </div>
              <div class="input-box">
                <label>A5.既往使用阿托品的浓度，使用频率，复查效果?(AL 增长 mm/time)</label>
                <br /><div class="input-div">{{info.AtropineText}}</div>
              </div>
              <div class="input-box">
                <label>A6. 是否使用其他近视防控措施？</label>
                <br /><div class="input-div">{{info.otherText}}</div>
              </div>
            </section>
          </div>
          <div class="section-box">
          <section class="section">
            <div class="section-titile">
              <label>B.视力检查</label>
              <span>检查结果 </span>
              <span class="checked">
                <img v-if="info.isCheckVision" class="checked-img" src="@/assets/checked.png" alt="">
                <span v-else>□</span>
              </span>
            </div>
            <table class="eyes-table" border="1px" cellspacing="0px" style="border-collapse:collapse">
              <tr>
                <th></th>
                <th>矫正视力</th>
                <th>最佳矫正视力</th>
              </tr>
              <tr>
                <td>OD</td>
                <td>{{info.od_vision}}</td>
                <td>{{info.od_best_vision}}</td>
              </tr>
              <tr>
                <td>OS</td>
                <td>{{info.os_vision}}</td>
                <td>{{info.os_best_vision}}</td>
              </tr>
            </table>
          </section>
          <section class="section">
            <div class="section-titile">
              <label>C. 生物参数检查</label>
              <span>检查报告 </span>
              <div class="small-input">{{info.checkType === 'iol_master' ? 1 : 2}}</div>
            </div>
            <span class="input-name">1.IOL-MASTER</span>
            <span class="input-name">2.NIDEK</span>
          </section>
          <section class="section">
            <div class="section-titile">
              <label>D. 散瞳</label>
              <div class="small-input">{{info.mydriasis ? 1 : 0}}</div>
              <span>1.YES</span>
              <span style="margin-left: 10px"></span>
              <span>2.NO</span>
            </div>
          </section>
          <section class="section">
            <div class="section-titile">
              <label>E. 自动验光</label>
              <span>检查报告 </span>
              <span class="checked">
                <img v-if="info.automatic" class="checked-img" src="@/assets/checked.png" alt="">
                <span v-else>□</span>
              </span>
            </div>
          </section>
          <section class="section">
            <div class="section-titile">
              <label>F. 备注</label>
              <span>其他特殊情况 </span>
              <span class="checked">
                <img v-if="info.isNeedRemark" class="checked-img" src="@/assets/checked.png" alt="">
                <span v-else>□</span>
              </span>
            </div>
            <div class="input-box">
              <div class="input-div">{{info.remark}}</div>
            </div>
          </section>

          </div>
        </div>
      </div>
      <div class="consent-page">
          <p style="text-align: right">方案号:1.0，20200510 版</p>
          <div class="sloder"></div>
          <h1>知情同意书</h1>
          <section>
            <p>方案名称:低强度单波长红光控制青少年近视疗效的前瞻性多中心真实世界研究</p>
            <p>方案编号:</p>
            <p>方案版本号:2.0，20200510 版</p>
            <p>知情同意书版本号:1.0，20200510 版</p>
            <p>研究机构:中山大学中山眼科中心</p>
            <p>主要研究者(负责研究医师):曾骏文</p>
            <br />
            <p>尊敬的受试者家长/监护人:</p>
          </section>
          <section class="section">
            <p>您的孩子将被邀请参加一项临床研究。本知情同意书提供给您一些信息以帮
              助您决定是否参加此项临床研究。请您仔细阅读，如有任何疑问请向负责该项研 究的研究者提出。
            </p>
            <p>
              您及孩子参加本项研究是自愿的。本次研究已通过本研究机构伦理审查委员 会审查。
            </p>
            <p>
              <strong>背景:</strong>近视为最常见的屈光不正状态，中国是青少年近视患病率世界上最高 的国家之一，居住在城市的青少年近视患病率为 15-80%，是其他国家人群患病 率的几倍至几十倍，我国每年用于近视矫正的光学手段或手术的高额费用都造成 了社会的沉重负担。预防近视和控制近视发展已成为目前社会亟需解决的公共卫 生问题。由我院主导的多中心随机对照研究(项目名称:低强度单波长红光对减 缓学龄儿童近视进展的有效性研究，伦理批件号:2019KYPJ093)初步结果表明， 低强度单波长红光是安全的干预措施，不会造成视网膜和黄斑可观测到的功能性 损害，同时可显著减缓学龄儿童近视的进展，中期分析结果表明，与对照组比较， 在眼轴增长这一主要研究指标上，控制率达到 96%。虽然前期随机对照临床试验 已经明确了治疗效果和安全性，但是由于设置了严格的入选条件和严格的治疗依 从性的要求，无法明确治疗剂量和治疗效果的关系、无法评估在更大的年龄段范 围、更高的近视程度的儿童中的治疗效果，并且无法评估联合使用角膜塑性镜、 低浓度阿托品眼药水、渐进/双光眼镜等干预措施的交互作用。
            </p>
            <p>因此，本研究将首次以 7-18 岁的青少年为主体，采取前瞻性多中心真实世 界研究设计，评估低强度单波长红光对控制青少年近视的作用。如获得成功，将 为综合防控青少年近视提供更广阔的使用前景。</p>
            <p><strong>研究目的:</strong>以 7-18 岁青少年为受试者，评估低强度单波长红光治疗仪对控制青少年近视的前瞻多中心性真实世界研究。通过对大样本的治疗群体的研究，明确:1)治疗剂量和治疗效果的关系，明确治疗有效的最低照射剂量;2)对不 同程度的近视(包括高度近视)的治疗效果;3)对青少年近视发展的整个年龄 段范围内的治疗效果和相关因素;4)与其他近视控制方法(角膜塑形镜、阿托 品、近视控制眼镜)的联合治疗的效果。</p>
          </section>
          <section>
            <ul>
              <h2>纳入标准：</h2>
              <p>1)7-18 岁青少年;</p>
              <p>2)确诊近视:睫状肌麻痹后双眼等效球镜-1.0D 及以上;</p>
              <p>3)包括现在或既往使用近视控制手段者(角膜塑性镜、低浓度阿托品、近视控制眼镜);</p>
              <p>4)受试者有参与红光治疗的意愿</p>
            </ul>
            <ul>
              <h2>排除标准:</h2>
              <p>1)存在斜视或其它双眼视觉异常;</p>
              <p>2)存在其它重大眼部和全身病异常;</p>
              <p>3)研究医生认为不适合纳入项目的其他原因;</p>
            </ul>
          </section>
          <section class="section">
            <p>
              <strong>研究过程:</strong>本研究为前瞻性多中心真实世界研究，以 7-18 岁的青少年为受 试者，纳入的受试者除进行常规的学习生活外，可正常使用其他正在使用的近视 干预措施(角膜塑性镜、低浓度阿托品眼药水、近视控制眼镜)，并在周一至周 五每天接受两次治疗仪治疗(每次治疗持续 3 分钟，两次治疗间间隔 4 个小时或 以上)。研究时长 2 年，并于入组后 1 个月、6 个月、12 个月、18 个月、24 个 月 5 次全面眼部检查，包括视力检查、眼球生物学参数测量、睫状肌麻痹验光 (散瞳)、裂隙灯检查，以此对受试者的眼轴增长、等效球镜度数进展、除眼轴 外的其他生物学参数改变、以及裸眼和最佳矫正视力进行评估。
            </p>
            <p><strong>中止干预标准:</strong>受试者出现非预期性严重不良反应(包括视力急剧下降两行 以上或视野中心暗点)、受试者要求退出研究、或受试者中途接受眼内手术或屈 光手术.</p>
          </section>
          <section class="section">
            <h2>风险与不适：</h2>
            <p>1)本研究的干预措施使用的是艾尔兴弱视治疗仪，其产品已获国家安全检 测证书、二类医疗器械注册证，生产厂家具有医疗器械生产许可证，治疗仪已经 上市并且在各大医院广泛使用，在弱视治疗领域临床使用十余年，其对眼球结构 功能无已知副作用。其采用低强度单波长红光原理，使用二极管产生 650±10 nm 的单波长可见红光，到达眼部能量范围为 1.07 至 1.42mw, 经国家光电产品辐射 安全质量监督检验中心认证，其辐射类别属于I类辐射，根据我国GB 7247.1- 2012 《激光产品的安全第一部分:设备分类、要求》文件，其辐射在使用过程 中，可安全用于眼部。使用治疗仪后可能会有短暂的炫目、闪光盲、视后像光斑 等反应，闭眼休息数分钟，症状可自行缓解并消失。</p>
            <p>2)研究的检查过程中的睫状肌麻痹检查(散瞳)，部分儿童会出现短暂的 近距离阅读困难、怕光等不适，在 4-6 小时后，这些不适会完全消失，期间需要 家长密切关注并陪同。如出现畏光而行动不便，研究人员将提供遮光眼镜夹片， 以减少部分人出现的近距离阅读困难、怕光等不适。低于千分之一的人可能会发 生眼压升高、头痛、眼痛和呕吐(急性闭角型青光眼表现)。对于怕光、视近模 糊者，本研究将提供遮光眼镜，必要时提供近附加眼镜方便阅读，并对所有受试 对象进行裂隙灯初步筛查，眼房角非常狭窄的患者将不予以散瞳检查。如有受试 者出现眼压升高、头痛、眼痛等症状，现场将有工作人员进行及时药物处理和采 取转诊措施。</p>
            <p><strong>受益:</strong>所有入组受试者均可获得红光治疗仪一台(设备和服务由艾尔兴公司 提供);免费供给所有入组受试青少年一份临床试验意外保险及和试验相关的医 疗救治;对参与临床治疗的患者，进行数据采集和长期随访;并对受试者的信息 资料进行研究，为近视治疗提供必要的建议，或为疾病的研究提供有益的信息。</p>
            <p><strong>隐私问题:</strong>如果您决定参加本项研究，您参加试验及在试验中的个人资料均 属保密。负责研究医师及其他研究人员将使用您的医疗信息进行研究。这些信息 可能包括您的姓名、地址、电话号码、病史及在您研究来访时得到的信息。您的 档案将保存在有锁的档案柜中，仅供研究人员查阅。为确保研究按照规定进行， 必要时，政府管理部门或伦理审查委员会的成员按规定可以在研究单位查阅您的 个人资料。这项研究结果发表时，将不会披露您个人的任何资料。</p>
            <p>您可以选择不参加本项研究，或者在任何时候通知研究者要求退出研究，您 的数据将不纳入研究结果，您的任何医疗待遇与权益不会因此而受到影响。</p>
            <p>如果您需要其它治疗，或者您没有遵守研究计划，或者发生了与研究相关的 损伤或者有任何其它原因，研究医师可以终止您继续参与本项研究。</p>
            <p>您可随时了解与本研究有关的信息资料和研究进展，如果您有与本研究有关 的问题，或您在研究过程中发生了任何不适与损伤，或有关于本项研究参加者权 益方面的问题您可以通过电话与曾骏文教授团队联系;或与非直接参与研究的相 关人士交谈，中山大学中山眼科中心医学伦理委员会，电话:020-66610729。</p>
          </section>
          <div class="sloder"></div>
          <section class="section">
            <h1>知情同意签字页</h1>
            <p>我已经阅读了本知情同意书。</p>
            <p>我有机会提问而且所有问题均已得到解答。</p>
            <p>我理解参加本项研究是自愿的。</p>
            <p>我可以选择不参加本项研究，或者在任何时候通知研究者后退出而不会遭到歧视或报复，我的任何医疗待遇与权益不会因此而受到影响。</p>
            <p>如果我需要其它治疗，或者我没有遵守研究计划，或者发生了与研究相关的损伤或者有任何其它原因，研究医师可以终止我继续参与本项研究。</p>
            <p>我将收到一份签过字的“知情同意书”正本。</p>
          </section>
          <section @click="startSign('signatureUrl')" class="signature-button">
            <mt-button class="signature-label">受试者姓名</mt-button>
            <div class="signature-box">
              <img class="signature-img" v-if="signatureUrl" :src="signatureUrl" alt="">
            </div>
          </section>
          <section @click="startSign('fsignatureUrl')" class="signature-button">
            <mt-button class="signature-label">受试者父亲签名</mt-button>
            <div class="signature-box">
              <img class="signature-img" v-if="fsignatureUrl" :src="fsignatureUrl" alt=""></div>
          </section>
          <section @click="startSign('msignatureUrl')" class="signature-button">
            <mt-button class="signature-label">受试者母亲签名</mt-button>
            <div class="signature-box">
              <img class="signature-img" v-if="msignatureUrl" :src="msignatureUrl" alt="">
            </div>
          </section>
          <mt-button class="signature-label" type="primary" :disabled="uploadLoading" @click="submitSign">提交</mt-button>
        </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import moment from 'moment'
import oss from '../../utils/oss'
export default {
  name: 'home-page',
  data() {
    return {
      info: {},
      top: 100,
      width: 0,
      height: 0,
      activeKey: '',
      signatureUrl: '',
      fsignatureUrl: '',
      msignatureUrl: '',
      uploadLoading: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.width = window.screen.width
    this.height = window.screen.height  * 0.3
    document.getElementById('canvas').addEventListener('touchmove', function (e) {
      e.preventDefault()
    }, { passive: false })
    this.getUserInfo()
  },
  updated: function () {
  },
  methods: {
    startSign (type) {
      this.activeKey = type
      this.lineCanvas()
    },
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    lineCanvas(obj) {
      this.lineWidth = 1;
      this.color = '#000';
      this.background = '#fff';
      this.canvas = document.querySelector('#canvas')
      if (!(this.canvas.getContext && this.canvas.getContext('2d'))) {
        this.$notify({ title: '对不起，当前浏览器暂不支持此功能！', type: 'error' })
        return
      }
      this.cxt = this.canvas.getContext('2d')
      this.cxt.fillStyle = this.background
      this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.height)
      this.cxt.strokeStyle = this.color
      this.cxt.lineWidth = this.lineWidth
      this.cxt.lineCap = 'round'
      this.cxt.lineJoin = 'round'
      this.cxt.shadowBlur = 1
      this.cxt.shadowColor = '#000'
    },
    handleTouchMove (e) {
      this.cxt.lineTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY - this.top)
      this.cxt.stroke();
    },
    handleTouchStart (e) {
      this.cxt.beginPath();
      this.cxt.moveTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY - this.top);
    },
    handleTouchEnd (e) {
      this.cxt.closePath();
    },
    clearRect () {
      this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.cxt.fillStyle = '#fff'
      this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    cancel () {
      this.activeKey = ''
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 0)
    },
    async saveImg () {
      var imgBase64 = this.canvas.toDataURL();
      this.imgBase64 = imgBase64
      await this.uploadBlobToOSS()
      this.activeKey = ''
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 0)
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async uploadBlobToOSS () {
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
      const id = new Date().getTime()
      const blob = this.dataURLtoBlob(this.imgBase64)
      try {
        const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
        this[this.activeKey] = result.url
        this.uploadLoading = false
      } catch (err) {
        this.uploadLoading = false
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
    },
    async getUserInfo () {
      const _id = this.$route.query._id
      let result = null
      try {
        result = await this.$store.dispatch('getInfo', {
          _id
        })
      } catch (err) {
        this.$notify({
          title: '请求失败',
          type: 'error'
        })
      }
      console.log(result)
      var info = result.data.data.info
      this.info = info
    },
    async submitSign () {
      const body = {
        _id: this.$route.query._id,
        signatureUrl: this.signatureUrl,
        fsignatureUrl: this.fsignatureUrl,
        msignatureUrl: this.msignatureUrl
      }
      if (!this.signatureUrl) {
        return Toast({
          message: '受试者请签名',
          duration: 3000
        })
      }
      if (!this.msignatureUrl || !this.fsignatureUrl) {
        return Toast({
          message: '监护人请签名',
          duration: 3000
        })
      }
      this.uploadLoading = true
      this.$store.httpDispatch({})('updateUserBackInfo', body).then((res) => {
        const { code } = res.data
        this.uploadLoading = false
        if (code === 0) {
          setTimeout(() => {
            this.$router.push('/')
          }, 3000)
          return Toast({
            message: '提交成功',
            duration: 3000
          })
        }
        if (code === 404) {
          return Toast({
            message: '找不到相关数据，请联系客服',
            duration: 3000
          })
        }
        if (code === 401) {
          Toast({
            message: '您已经签过名了哦，不可重复签名。',
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err.data)
        this.uploadLoading = false
        return Toast({
          message: err.data.msg,
          duration: 3000
        })
      })
    }
  }
}
</script>
<style lang="less">
  .hidden {
    display: none;
  }
  #canvas_body {
    position: fixed;
    left: 0;
    .mint-button {
      width: 30%;
      margin-left: 2%;
      margin-top: 100px;
    }
  }
  #canvas_body canvas {
    display: block;
  }
  .consent-body {
    background: #fff;
  }
  .consent-page {
    padding: 30px 20px;
    .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .section {
      text-indent: 2em;
    }
    .signature-button {
      margin-bottom: 20px;
      text-align: left;
    }
    .signature-img {
      width: 50%;
      height: 50px;
    }
    .signature-label {
      width: 45%;
    }
    .signature-box {
      border-bottom: 2px solid #333;
      display: inline-block;
      margin-left: 10px;
      width: 50%;
      vertical-align: bottom;
    }
  }
  .errorCanvas {
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateY(40%);
  }
  .info-page {
    margin: 20px 50px;
    background: #fff;
    h1 {
      text-decoration: underline;
      font-size: 15px;
      margin: 0 2px;
    }
    .print_header p {
      margin-bottom: 2px;
    }
  .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    .checked {
      span {
        font-size: 30px;
      }
      img {
        position: relative;
        top: 2px;
        width: 14px;
      }
    }
    .section-box {
      vertical-align: top;
    }
    .section {
      border-top: 3px solid #333;
      padding: 5px 10px;
      margin-bottom: 50px;
      text-align: left;
      table {
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
      .small-input {
        width: 15px;
        height: 15px;
        padding: 0 0;
        margin: 0 0;
        font-weight: 800;
        display: inline-block;
        line-height: 20px;
        text-align: center;
        margin: 0 10px;
        border: 1px solid #333!important;
      }
      .input-box {
        margin-bottom: 20px;
        font-size: 13px;
        label {
          display: inline-block;
        }
      }
      .inline-div {
        display: inline-block;
        width: 50px;
        vertical-align: bottom;
      }
      .border-input {
        border: 1.5px solid #333!important;
        display: inline-block;
        width: 100px;
      }
      .inline-box {
        width: 135px;;
        display: inline-block;
      }
      .input-box .input-div {
        border: 0;
        width: auto;
        border-bottom: 1px solid #333;
        margin-top: 20px;
        word-break: break-all;
      }
      .eyes-table {
        margin-top: 15px;
        td, th {
          height: 40px;
        }
      }
      .input-name {
        font-size: 17px;margin-right: 30px;
        font-weight: 500;;
      }
      .section-titile {
        background: #D0CECE;
        text-align: right;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        padding: 0 10px;
        box-sizing: border-box;
        label {
          font-size: 17px;
          float: left;
          font-weight: 800;
        }
      }
    }
  }
</style>

<template>
  <div class="upload-data-page">
    <mt-popup
      class="image-popup"
      v-model="popupVisible">
      <img v-if="key==='image_vision'" src='../../assets/image_vision.jpg'>
      <img v-if="key==='image_mydriatic'" src='../../assets/image_mydriatic.jpg'>
      <img v-if="key==='image_biometric'" src='../../assets/image_biometric.jpg'>
      <img v-if="key==='color_fundus'" src='../../assets/image_fundus.jpeg'>
    </mt-popup>
    <div class="upload-button" v-for="item in keys" :key="item.key">
      <div class="label-title">{{item.text}}</div>
      <div class="upload-label">
        <div class="upload-input">图片数量：{{imageBody[item.key].length}}</div>
        <mt-button @click="chooseImage(item.key)"  type="default">选择图片</mt-button>
      </div>
      <img @click="showImage(item.key)" v-if="item.key==='image_vision'" src='../../assets/image_vision.jpg'>
      <img @click="showImage(item.key)" v-if="item.key==='image_mydriatic'" src='../../assets/image_mydriatic.jpg'>
      <img @click="showImage(item.key)" v-if="item.key==='image_biometric'" src='../../assets/image_biometric.jpg'>
      <img @click="showImage(item.key)" v-if="item.key==='color_fundus'" src='../../assets/image_fundus.jpeg'>
    </div>
    <div v-if="uploaded" class="upload-success">
      <img src="../../assets/img_upload_success.png" alt="">
    </div>
    <mt-button v-if='!uploaded' class="upload-btn" @click="uploadImage" type="primary">提交</mt-button>
    <mt-button v-else class="upload-btn" @click="goBack" type="primary">返回</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { Indicator, Toast } from 'mint-ui';
export default {
  name: 'upload-photo-page',
  data() {
    return {
      images: [],
      key: '',
      popupVisible: false,
      keys: [
        {
          key: 'image_vision',
          text: '含视力结果的病历'
        }, {
          key: 'image_mydriatic',
          text: '散瞳验光结果'
        }, {
          key: 'image_biometric',
          text: '生物测量结果'
        },
        {
          key: 'intraocular_pressure',
          text: '眼压'
        },
        {
          key: 'color_fundus',
          text: '眼底彩照'
        }
      ],
      imageBody: {
        image_vision: [],
        image_mydriatic: [],
        image_biometric: [],
        intraocular_pressure: [],
        color_fundus: []
      },
      localData: '',
      uploaded: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
  },
  methods: {
    showImage (key) {
      this.key = key
      this.popupVisible = true
    },
    goBack () {
      this.$router.push({
        path: '/'
      })
    },
    uploadImage () {
      let flag = false
      this.keys.forEach((item) => {
        if (flag) {
          return false
        }
        if (!this.imageBody[item.key].length) {
          flag = true
          return Toast({
            message: `请上传${item.text}`,
            duration: 3000
          })
        }
      })
      if (flag) {
        return false
      }
      Indicator.open({
        text: '正在上传...',
        spinnerType: 'fading-circle'
      })
      this.$store.httpDispatch({})('uploadImage', this.imageBody).then(() => {
        Indicator.close()
        this.uploaded = true
      }).catch((err) => {
        Indicator.close()
        console.log(err)
        Toast({
          message: '请求失败',
          duration: 3000
        })
      })
    },
    // wx upload image
    chooseImage (key) {
      const that = this
      if (that.imageBody[key].length >= 5) {
        return Toast({
          message: '每种报告最多只能选择5张照片',
          duration: 3000
        })
      }
      wx.chooseImage({
        count: 5, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          that.imageBody[key] = []
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          let length = localIds.length
          let i = 0
          const upload = () => {
            if (i >= length) {
              return
            }
            let image = {}
            image.localId = localIds[i]
            wx.uploadImage({
              localId: image.localId, // 图片的localID
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: function (res) {
                var serverId = res.serverId; // 返回图片的服务器端ID
                image.serverId = serverId
                that.imageBody[key].push(image)
                i++
                upload()
              }
            })
          }
          upload()
        }
      })
    }
  }
}
</script>
<style lang="less">
.upload-data-page {
  .upload-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-top: 30px;
    position:fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -40vw;
  }
  .image-popup {
    width: 85vw;
    height: 80vh;
    overflow: scroll;
    img {
      width: auto;
      height: 80vh;
    }
  }
  .upload-success {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    img {
      margin: 100px auto;
      width: 100px;
    }
  }
  .upload-button {
    margin: 5px 5px;
    .label-title {
      font-size: 18px;
      width: 80vw;
      margin-left: 10vw;
      text-align: left;
    }
    .upload-label {
      display: flex;
      width: 79vw;
      margin-left: 10vw;
      margin-bottom: 5px;
      .upload-input {
        background:#fff;
        height: 40px;
        flex: 1;
        font-size: 13px;
        padding-left: 5px;
        box-sizing: border-box;
        overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
        line-height: 40px;
      }
      .mint-button {
        width: 100px;
        margin-left: 10px;
      }
    }
    img {
      width: 80vw;
      max-height: 140px;
    }
    .uploaded-img {
      text-align: left;
      img {
        width: 100px;
        height: 100px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>

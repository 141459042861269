

<template>
  <div>
    <div class="report" :id="ruleForm._id" style="  ">
      <img  src="@/assets/logo_2.png" style="width: 200px;margin-top: 20 0;" alt="">
      <h3 style="width: 220px;float:right;">编号：{{ruleForm.code}}</h3>
      <h1 style="margin-top:50px;text-align: center;color: #2889CF;font-size: 35px;">艾尔兴服务协议</h1>
      <p>
        甲方姓名（购买方）：<span class="underline">{{ruleForm.name}}</span>（使用人的监护人），
        联系电话：<span class="underline">{{ruleForm.mobile}}</span>
      </p>
      <p>
        身份证号码：<span class="underline">{{ruleForm.idCode}}</span> 地址：<span class="underline">{{ruleForm.address}}</span>
      </p>
      <p>
        使用人姓名：<span class="underline">{{ruleForm.usename || '    '}}</span> 身份证号码：<span class="underline">{{ruleForm.useIdCode}}</span>
      </p>
      <br>
      <p>
        乙方（销售方）： <span class="underline">广州明煜视觉科技有限公司</span>
      </p>
      <p>
        联系方式： <span class="underline">020-87600696</span>
      </p>
      <p>
        地址： <span class="underline" style="text-align: left;">广州市天河区华夏路津滨腾越大厦南塔6楼602</span>
      </p>
      <p>甲、乙双方根据《中华人民共和国民法典》相关规定，在平等、自愿、信任的基础上，就甲方向乙方购买艾尔兴近视弱视综合治疗仪事宜，签订本协议如下，双方共同遵守。
      </p>
      <h3>第一条  购买条件
      </h3>
      <p>甲方在同时满足下列条件后，方可与乙方签订本协议。
      </p>
      <p>
        1、甲方需向乙方提供使用人最近20天在医院眼科的眼部检查/检验/检测报告。眼部检查至少应包括：
      </p>
      <p>
        ①进行医学验光，测量双眼的屈光度数、眼压和裸眼视力(戴眼镜者要查矫正视力）。
      </p>
      <p>
        ②使用眼生物测量仪或IOL-MASTER等仪器测量眼轴长度、角膜曲率。
      </p>
      <p>
        ③检查眼位、眼底，确保眼底无器质性病变。
      </p>
      <p>2、乙方已告知甲方艾尔兴近视弱视综合治疗仪适用范围及禁忌症，甲方确认使用人非禁忌症患者，符合适用范围，可以使用艾尔兴近视弱视综合治疗仪。
      </p>
      <p>①艾尔兴适应症：可适用于近视辅助治疗；可适用于屈光不正性、屈光参差性及斜视性弱视患者。使用年龄3~16岁或遵医嘱。
      </p>
      <p>②艾尔兴禁忌症：1)有光过敏史的患者。2)眼底视网膜病变、白内障或其他眼内疾病的患者。3)视神经受损或先天性视神经功能障碍的患者。
      </p>
      <p>
        ③光晕症患者、多动症患者和银屑病患者禁止使用本产品，视神经病变患者和高眼压患者需在医生指导下使用本产品。
      </p>
      <h3>
        第二条 设备明细及款项支付
      </h3>
      <div v-if="ruleForm.agreementType == '租用三个月&一年'">
        <p>（1）设备租赁服务，租赁设备信息见下表：</p>
        <table border="1" style=" border-collapse:collapse;margin: 0 auto;">
          <tr>
            <td >租赁设备名称</td>
            <td >规格/型号</td>
          </tr>
          <tr>
            <td >艾尔兴近视弱视综合治疗仪</td>
            <td >{{ ruleForm.deviceType === '医疗版' ? 'RS-200-2A' : 'RS-200-1B'}}</td>
          </tr>
        </table>
        <p>
          ①设备交付：甲方选择 <img  class="checked" v-if="ruleForm.transferType ==='邮寄交付'" src="@/assets/checked.png" alt="">
        <span v-else class="fk">□</span>邮寄交付/
        <img  class="checked" v-if="ruleForm.transferType ==='现场交付'" src="@/assets/checked.png" alt="">
        <span v-else class="fk">□</span>现场交付
        </p>
        <p>
          <img  class="checked" v-if="ruleForm.transferType ==='邮寄交付'" src="@/assets/checked.png" alt="">
          <span v-else class="fk">□</span>
          邮寄交付：乙方将在收到全额设备款之日起三个工作日内将设备及使用方法、注意事项等一并邮寄交付给甲方，运费
          由乙方负担。如因甲方原因需二次邮寄的（包括但不限于派送失败退回、甲方无正当理由要求更换设备等，二次邮寄的运费由甲
          方负担。
        </p>
        <p>
          甲方确认收件信息：收件人<span class="underline">{{(ruleForm.sentName || '/')}}</span>收件电话：<span class="underline">{{(ruleForm.sentPhone  || '/')}}</span>
        </p>
        <p>
          收件地址：<span class="underline">{{(ruleForm.sentAddress || '/')}}</span>
        </p>
        <p>
          <img  class="checked" v-if="ruleForm.transferType ==='现场交付'" src="@/assets/checked.png" alt="">
          <span v-else class="fk">□</span>现场交付：乙方已于<span class="underline">{{getDate(ruleForm.sentDate)}}</span>将设备及使用方法、注意事项等一并交付给甲方。
        </p>
        <p>②设备验收：甲方应于收到设备当日进行验收，如设备存在破损、型号不符、无法开关机应于三日内提出，否则视为没有异议，设备验收合格。
        </p>
        <p>③设备归还：甲方应当在服务期满/终止本协议之日起七天内将设备及礼品（如有）送至乙方业务点或者邮寄至乙方指定收货地址（相关运费由甲方承担）。如有逾期，应按日向乙方支付违约金100元。
        </p>
        <p>（2）客服服务，包括租赁设备维修、更换服务；租赁设备使用、指导服务；租赁设备使用反馈追踪服务等。乙方配备了专业的客服人员，提供客服服务。
        </p>
        <p>
          2、服务期限合计<span class="underline">{{(ruleForm.useMonths)}}</span>个月，服务期限届满，甲方如需继续使用，应与乙方另行签订销售协议，购买设备继续使用。</p>
        <p>
          3、设备押金：<span class="underline">{{(ruleForm.guarantee)}}</span>人民币元。押金于本协议签订当日支付。服务期满，甲方按本协议及时交还设备，经检测设备无人为损坏/故障等情形，经乙方审核通过后无息退还押金。
        </p>
        <p>
          4、服务费用：<span class="underline">{{(ruleForm.firstAmount)}}</span>人民币元。甲方应于本协议签订当日付清服务费用。/故障等情形，经乙方审核通过后无息退还押金。
        </p>
      </div>
      <div>
        <p>
          为保障使用效果，甲方应敦促使用人严格按照本协议及附件使用方法、注意事项使用设备
        </p>
        <p>
          1、使用次数及时长：每天2次，每次3分钟，两次使用应间隔4小时或以上。
        </p>
        <p>
          特别说明：艾尔兴近视弱视综合治疗仪每天仅可使用两次，如当天遗忘使用，后续无法增加使用次数，亦不返还服务费用。
        </p>
        <p>
          2、为保障近视防控效果，甲方须保证使用人在服务期内：
        </p>
        <p>
          A、养成良好的用眼习惯。每次看电子屏幕（包括电脑、手机、电视、游戏机等）时间不超过20分钟，每天累计看电子屏幕不超过2小时(上课投影仪除外)；(上课投影仪除外)；
        </p>
        <p>
          B、建议增加一定量的户外活动，增加眼睛接触阳光的机会与时长，不可直视太阳。
        </p>
        <p>
          C、定期检查视力情况，每三个月到医院检查眼轴长度和屈光度数，并将检查资料/结果/报告及时反馈给乙方建立专属的视力健康档案，以便乙方留存档案及提供相关服务。
        </p>
        <p>
          D、乙方专家会根据使用者实际情况作使用方案上的调整，甲方须听从乙方专家意见。
        </p>
        <p>
          E、使用人配合度须在80%以上（配合度=实际使用次数/应使用次数），治疗开始时间以第一次治疗时间为准。
        </p>
        <p style="">
          F、裸眼视力0.6以下或近视超过-1.00D的使用人，日常生活需要戴眼镜。
        </p>
        <p>
          3、服务期内，甲方须妥善保管设备，严格按照设备说明使用设备，以保障设备的正常运行。甲方在使用设备过程中有任何疑问，应及时与乙方沟通，解决问题。乙方已配备专业的客服人员，指引甲方正确使用设备，提示甲方定期检查设备状态。
        </p>
        <p>
          4、若设备故障或损坏无法使用，甲方需及时联系乙方进行维修或更换。经设备厂家检测属于自然故障或损坏的，维修、更换费用由乙方承担。若非自然故障或损坏（包括但不限于人为损坏/使用不当导致等），维修费用、运费等相关费用由甲方承担。若设备毁损，甲方应按照设备价值全额赔付给乙方。
        </p>
      </div>
      <div>
        <h3>
          第四条 承诺与告知
        </h3>

        <p>
          1、甲方保证其提供给乙方的使用人眼部报告/结果/数据真实、完整、有效，乙方不对甲方提供的报告、数据作实质审查。
          甲方保证其已仔细阅读本协议及附件内容、已仔细阅读艾尔兴近视弱视综合治疗仪的使用方法、注意事项等，清楚艾尔兴近视弱
          视综合治疗仪的适用范围及禁忌症，保证使用人符合适用范围，可以使用艾尔兴近视弱视综合治疗仪。
        </p>
        <p>
          2、甲方保证其及使用人将严格按照本协议及附件、艾尔兴近视弱视综合治疗仪的使用方法、注意事项等使用艾尔兴近视弱
          视综合治疗仪。
        </p>
        <p>
          3、乙方已告知甲方青少年视力发育是个动态生长的过程，存在生理性增长，每个人使用艾尔兴近视弱视综合治疗仪的效果均不同，甲方对此清楚且无异议。
        </p>
        <p>
          4、乙方保证其提供给甲方的设备已取得权利人授权，是符合国家标准、经合法注册的合格产品。
        </p>
        <p>
          5、乙方承诺以下内容至少有一项可以达成：
        </p>
        <p>
          （1）在使用人按本协议要求规范使用艾尔兴近视弱视综合治疗仪前三个月内，控制眼轴长度不过度增长（以同一台眼生物测量仪或IOL-MASTER测量的眼轴长度为判定依据），具体如下：
        </p>
        <p>
          ①患者年龄处于3-12岁，眼轴三个月增长不超过0.2mm。
        </p>
        <p>
          ②患者年龄处于13-18岁，眼轴三个月增长不超过0.15mm。
        </p>
        <p>（2）在使用人按本协议要求规范使用艾尔兴近视弱视综合治疗仪前三个月内，使用人散瞳验光后单眼等效球镜增长不超过-0.50D（上下波动50度属正常检测误差范围，以使用前后数据为依据）。
        </p>
        <h3>
          第五条 协议终止及退费</h3>
          <p>
            1、不适用服务终止：即服务期限内，经公立三甲医院眼科专科医生诊断报告和检验报告诊断使用人不适合使用设备，甲方可向乙方申请终止协议。甲方申请经乙方审核通过后，双方确认服务协议终止，乙方自收到甲方退还的设备（经检测无损）后七个工作日内返还剩余服务费用【退费标准：服务费用-已使用期限的费用，费用标准为每月1200元，使用不足1个月的按1个月计算】，如服务期限剩余不足30天，乙方不予退费。
          </p>
          <p>2、不符承诺终止：即经公立三甲医院眼科专科医生诊断报告和检验报告诊断证明乙方未实现本协议第四条第5款承诺的，甲方可申请终止本协议。乙方在收到设备（经检测无损）七个工作日内返还剩余服务费用【退费标准：服务费用-已使用期限的费用，费用标准按一个月期/三个月期/一年期，使用不足1个月的按1个月计算】，如服务期限剩余不足30天，乙方不予退费。
          </p>
          <p>
            3、本协议一经签订，除本协议约定事项外，任一方不得单方面无理由提前终止协议。甲方无正当理由提前终止协议的，服务费用不予退还
          </p>
        <h3>
          第六条 其他约定
        </h3>
        <p>1、本协议解释权归乙方。如有争议，双方协商解决，协商不成由乙方所在地人民法院裁决。违约方并需负担守约方支出的律师费、担保服务费等合理费用支出。
        </p>
        <p>2、本协议一式两份, 甲乙双方各执一份，自甲、乙双方签字盖章之日起生效，每份具有同等法律效力。
        </p>
        <p>3、本协议未尽事宜，甲、乙双方可根据国家法律、法规及有关规定协商另行签订补充协议，双方共同遵守执行。
        </p>
      </div>

      <p>
      </p>
      <div style="margin-top: 30px">
        <div style="display:inline-block;width:50%">
          <div style="height: 40px">
            <span>
               甲方签字：
            </span>
            <span>
              <img  v-if="ruleForm.signatureUrl" style="height: 40px;vertical-align: middle"
              crossOrigin="Anonymous"
              :src="ruleForm.signatureUrl + '?_now_='+new Date().getTime()" alt="">
            </span>
          </div>
          <div style="margin-top: 30px"  v-if="ruleForm.signatureUrl">
            日期: <span class="underline" v-if="ruleForm.signAt">{{getDate(ruleForm.signAt)}}</span>
          </div>
          <div style="margin-top: 30px" v-else>
            日期: ___年___月__日
          </div>
        </div>
        <div style="display:inline-block;width:50%;position: relative">
          <div style="height: 40px">
          乙方盖章：
          </div>
          <div style="margin-top: 30px"  v-if="ruleForm.createdAt">
            日期: <span class="underline" v-if="ruleForm.createdAt">{{getDate(ruleForm.createdAt)}}</span>
          </div>
          <div style="margin-top: 30px" v-else>
            日期: ___年___月__日
          </div>
          <img  style="position:absolute;width: 40%;top: 0px;right: 30%;" alt="Vue logo" src="@/assets/printsign.png">
        </div>
      </div>
      <p>
      </p>
    </div>
  </div>
  </template>
  <script>
  import moment from 'moment'
  import html2canvas from 'html2canvas'
  import oss from '../../utils/oss'
  export default {
    props: ['ruleForm', 'print'],
    data() {
      return  {
        sts: {},
        dataUrl: ''
      }
    },
    beforeMount () {
    },
    methods: {
      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      async uploadBlobToOSS (imgBase64) {
        this.uploadLoading = true
        try {
          this.sts = await oss.getSTS()
        } catch (err) {
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
        const id = new Date().getTime()
        const blob = this.dataURLtoBlob(imgBase64)
        try {
          const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
          this.uploadLoading = false
          this.$store.httpDispatch({})('saveAgreement', {
            _id: this.ruleForm._id,
            imageUrl: result.url
          }).then((res) => {
            const { code } = res.data
            this.uploadLoading = false
          }).catch((err) => {
            console.log(err.data)
            this.uploadLoading = false
          })
        } catch (err) {
          this.uploadLoading = false
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
      },
      getDate (createdAt) {
        if (!createdAt) {
          return '/'
        }
        return moment(createdAt).format('YYYY年MM月DD日')
      }
    }
  }
  </script>
  <style lang="less" scoped>
  @media (max-width: 767px) {
    .report {
      padding:  0 0!important;
    }

  }
  .report {
    padding: 50px 30px;
    .underline {
      // text-decoration:underline;
      font-weight: 800;
      border-bottom: 1px solid #777;
      display: inline-block;
      text-align: center;
      min-width:50px;
    }
    .checked {
       width: 12px;
       vertical-align: top;
       position: relative;
       top: 4px;
    }
    .bold {
      font-size: 22px;
      font-weight: 800;
    }
    .fk {
      font-size: 20px;
    }
    p {
      font-size: 13px;
      margin: 5px 0;
      line-height: 22px;
    }
    h3 {
      font-size: 15px;
      margin: 7px 0;
    }
    table td
    {
      width: 200px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid black;
    }
    footer {
      text-align: center;
      width: 100%;
    }
  }
  </style>
<template>
  <div class="doctor-list">
    <img class="doctor-list-logo" src="../../assets/doctor_list_logo.png" />
    <div class="doctor-item" v-for="(obj, index) in list" :key="index">
      <div class="doctor-headimg">
        <img src="../../assets/doctor_headimg.png" >
      </div>
      <div class="doctor-body">
        <div class="doctor-title">
          <span class="doctor-nickname">{{obj.nickname}}</span>
          <span class="doctor-job">{{obj.job}}</span>
        </div>
        <div class="doctor-text">
          {{obj.text}}
        </div>
      </div>
    </div>
    <mt-button class="start-chat-btn" @click="startChat" type="primary">开始咨询</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex'
export default {
  name: 'SessionList',
  data() {
    return {
      list: [
        {
          headimgurl: '',
          nickname: '艾尔兴',
          job: '主任专家',
          text: '擅长领域：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
        },
        {
          headimgurl: '',
          nickname: '艾尔兴',
          job: '主任专家',
          text: '擅长领域：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
        },
        {
          headimgurl: '',
          nickname: '艾尔兴',
          job: '主任专家',
          text: '擅长领域：xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
        }
      ]
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
  },
  methods: {
    startChat () {
      this.$router.push('/chat')
    }
  }
}
</script>

<style lang="less">
.doctor-list {
  padding: 20px 20px;
  .start-chat-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .doctor-list-logo {
    width: 60%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .doctor-item {
    margin-bottom: 20px;
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    .doctor-headimg {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
      }
    }
    .doctor-body {
      padding-left: 10px;
      flex: 1;
      box-sizing: border-box;
      text-align: left;
      .doctor-title {
        margin-bottom: 5px;
        .doctor-nickname {
          font-weight: 900;
          font-size: 18px;
          font-family: '黑体';
        }
        .doctor-job {
          margin-left: 5px;
        }
      }
      .doctor-text {
        word-break: break-all;
      }
    }
  }
}
</style>

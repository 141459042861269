<template>
  <div class="session-list">
    <mt-field style="padding-right: 20px" v-if="user.type === 'customer'" label="是否在线">
      <mt-switch @change="updateUserOnline" v-model="online"></mt-switch>
    </mt-field>
    <div class="session-item" v-for="(session) in list"
      :key="session._id"
      @click="goChat(session)">
      <div class="session-headimg">
        <img :src="session.fromheadimgurl" >
      </div>
      <div class="session-body">
        <div class="session-title">
          <span class="session-nickname">{{session.fromnickname}}</span>
        </div>
        <span v-if="session.noReadCount > 0" class="session-count">
          {{ session.noReadCount }}
        </span>
      </div>
    </div>
    <mt-button class="back-hone-btn" @click="$router.push('/')" type="primary">返回主页</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
export default {
  name: 'SessionList',
  data() {
    return {
      list: [],
      online: false
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
  //触发后台socket事件
    this.getSessions()
    this.online = this.user.online
  },
  methods: {
    goChat (session) {
      this.$router.push({path: '/chat', query: {sessionId: session._id}})
    },
    updateUserOnline () {
      this.$store.httpDispatch({})('updateOnline', {
        online: this.online
      }).catch((err) => {
        return Toast({
          message: '请求失败',
          duration: 3000
        })
      })
    },
    getSessions () {
      if (this.user.type === 'doctor') {
        this.$store.httpDispatch({})('getSessionsByDoctor')
          .then((res) => {
            console.log(res)
            if (res.data.code === 0) {
              this.list = res.data.data.sessions
            }
          })
      } else if (this.user.type === 'customer') {
        this.$store.httpDispatch({})('getSessionsByCustomer')
          .then((res) => {
            console.log(res)
            if (res.data.code === 0) {
              this.list = res.data.data.sessions
            }
          })
      }
    }
  }
}
</script>

<style lang="less">
.session-list {
  .mint-cell-wrapper {
    text-align: left;
    padding: 20px 0 20px 10px;
    img {
      width: 50px;
    }
  }
  .back-hone-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }

  .session-item {
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    border-bottom: 1px solid #eee;
    .session-headimg {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
      }
    }
    .session-body {
      padding-left: 10px;
      flex: 1;
      box-sizing: border-box;
      text-align: left;
      line-height: 60px;
      .session-title {
        margin-bottom: 5px;
        .session-nickname {
          font-weight: 900;
          font-size: 18px;
          font-family: '黑体';
        }
        .session-job {
          margin-left: 5px;
        }
      }
      .session-count {
        position: absolute;
        right: 20px;
        top: 10px;
        color: red;
        font-size: 15px;
        font-weight: 800;
      }
    }
  }
}
</style>

<template>
  <div style="padding-top: 60px">
    <h2>
      押金：{{user.plan.deposit}}元
    </h2>
    <mt-button class="user-btn" @click="submit" type="primary">支付押金</mt-button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast, Indicator} from 'mint-ui'
import moment from 'moment'
export default {
  components: {
  },
  name: 'home-page',
  data() {
    return {
      loaded: false,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    // if (typeof WeixinJSBridge == "undefined") {
    //     if (document.addEventListener) {
    //         document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    //     } else if (document.attachEvent) {
    //         document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
    //         document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    //     }
    // } else {
    //     onBridgeReady();
    // }
  },
  updated: function () {
  },
  methods: {
    onBridgeReady(payment) {
      const that = this
      WeixinJSBridge.invoke('getBrandWCPayRequest', payment,
      function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Indicator.open({
              text: '正在查询支付结果，请稍后...',
              spinnerType: 'fading-circle'
            });
            setTimeout(() => {
                Indicator.close()
                that.$store.httpDispatch({})('getUserInfo').then((response) => {
                const { code, data } = response.data
                if (code === 0) {
                  that.$store.dispatch('setUser', { user: data.user })
                  that.$router.push('/')
                }
              }).catch((err) => {
                console.log(err)
              })
            }, 5000)
          }
      });
    },
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    submit: function (){
      const authed = this.getQueryVariable('authed')
      this.$store.httpDispatch({})('payDeposit', {
      }).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          console.log(data)
          this.onBridgeReady(data.result.payment)
          // push to  agreement
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })

    },
  }
}
</script>
<style lang="less">
  .user-btn {
    height: 40px;
    margin-top: 20px;
    line-height: 40px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .hidden {
    display: none;
  }
</style>

<template>
  <div class="pay-page" style="padding-top: 30px">
    <div class="package-list">
      <div :class="payPackage === pk._id ? 'package-item active' :'package-item'" @click="payPackage = pk._id" v-for="pk in list" :key="pk._id">
        <span class="package-name">
         {{ pk.name }}
        </span>
        <span class="package-price">
          ￥{{ pk.price }}
        </span>
        <span class="package-i">
          折合￥{{(pk.price /pk.months).toFixed(2)}}/月
        </span>
      </div>
    </div>
    <div @click="popupVisible = true" >
      <mt-cell title="优惠券" is-link>
        <span style="color: #777">
          <span v-if="!couponId" class="coupon-price">请选择优惠券</span>
          <span v-if="coupon.type === 'time'" class="coupon-price">{{ coupon.months }}个月免费体验</span>
          <span v-if="coupon.type === 'price'" class="coupon-price">抵扣{{ coupon.price }}元</span>
          <span v-if="coupon.type === 'discount'" class="coupon-price">{{ coupon.discount * 10}}折</span>
        </span>
      </mt-cell>
    </div>
    <mt-popup
      v-model="popupVisible"
      position="bottom">
      <Coupons :chooseCoupon="chooseCoupon" :couponId="couponId"/>
    </mt-popup>
    <mt-tab-container v-model="payPackage">
    <mt-tab-container-item v-for="pk in list" :key="pk._id" :id="pk._id">
      <span>
      </span>
    </mt-tab-container-item>
</mt-tab-container>
    <mt-button class="user-btn" @click="submit" type="primary">确认协议并支付</mt-button>
  </div>
</template>
<script>
import Coupons from './Coupons.vue'
import { mapState } from 'vuex'
import { Toast, Indicator } from 'mint-ui'
import moment from 'moment'
export default {
  components: {
    Coupons
  },
  name: 'home-page',
  data() {
    return {
      couponId: null,
      coupon: {},
      list: [],
      popupVisible: false,
      payPackagepackages: [],
      payPackage: '',
      loaded: false,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.getPackages()
  },
  updated: function () {
  },
  methods: {
    chooseCoupon (obj) {
      this.coupon = obj
      this.couponId = obj._id
      this.popupVisible = false
    },
    onBridgeReady(payment) {
      const that = this
      WeixinJSBridge.invoke('getBrandWCPayRequest', payment,
      function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Indicator.open({
              text: '正在查询支付结果，请稍后...',
              spinnerType: 'fading-circle'
            });
            setTimeout(() => {
                Indicator.close()
                that.$store.httpDispatch({})('getUserInfo').then((response) => {
                const { code, data } = response.data
                if (code === 0) {
                  that.$store.dispatch('setUser', { user: data.user })
                  that.$router.push('/')
                }
              }).catch((err) => {
                console.log(err)
              })
            }, 5000)
          }
      });
    },
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    getPackages () {
      this.$store.httpDispatch({})('getPackages')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.packages
          this.payPackage = this.list[0]._id
        }
      })
    },
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    submit: function (){
      const authed = this.getQueryVariable('authed')
      this.$store.httpDispatch({})('payPackage', {
        id: this.payPackage,
        couponId: this.couponId
      }).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          console.log(data)
          this.onBridgeReady(data.result.payment)
          // push to  agreement
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })

    },
  }
}
</script>
<style lang="less">
  .pay-page {
    .mint-popup {
      width: 100vw;
    }
    .mint-cell {
      .coupon-price {
        color: #007EC5;
        font-weight: 900;
      }
      margin-top: 20px;
      background: none;
      width: 90vw;
      margin-left: 5vw;
      border-bottom: 1px solid #aaa;
    }
    .mint-cell-allow-right::after {
      border-color: #777;
    }
    .mint-cell-title {
      text-align: left;
    }
  }
  .package-list {
    white-space: nowrap;
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    .package-item {
      width: 150px;
      border: 1px solid #eee;
      margin: 0 5px;
      background: #fff;
      padding: 20px 0;
      display: inline-block;
      border-radius: 5px;
      .package-name {
        display: block;
        font-size: 22px;
        color: #756F79;
        font-weight: 600;
      }
      .package-price {
        display: block;
        margin: 10px 0;
        color: #007EC5;
        font-size: 30px;
        font-weight: 900;
      }
      .package-i {
        font-size: 15px;
        color: #555;
      }

    }
    .active {
      border: 1px solid #007EC5;
      background: #e4f5ff;
    }
  }
  .user-btn {
    height: 50px;
    margin-top: 20px;
    line-height: 50px;
    background: #007EC5;
    width: 90vw;
    border-radius: 100px;
    margin-top: 40px;
  }
  .hidden {
    display: none;
  }
</style>

<template>
  <div class="counpon-page">
    <mt-field class="required" label="串码" v-model="code"></mt-field>
    <mt-button style="margin: 30px 0;" class="user-btn" @click="submit" type="primary">提交串码</mt-button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import moment from 'moment'
export default {
  components: {
  },
  name: 'home-page',
  data() {
    return {
      loaded: false,
      code: '',
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
  },
  updated: function () {
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    submit: function (){
      this.$store.httpDispatch({})('bindCoupon', {
        code: this.code
      }).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          Toast({
            message: '添加成功',
            duration: 3000
          })
          this.$store.httpDispatch({})('getUserInfo').then((res) => {
            const { code, data } = res.data
            if (code === 0) {
              this.$store.dispatch('setUser', { user: data.user })
              this.$router.push('/')
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
        if (err.data) {
          return Toast({
            message: err.data.msg,
            duration: 3000
          })
        } else {
          return Toast({
            message: '发生了未知错误',
            duration: 3000
          })
        }
      })

    },
  }
}
</script>
<style lang="less">
.counpon-page {
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 50px;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 100px;
      top: 5px;
      color: red;
    }
  }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        width: 100%;
        text-align: justify;
        vertical-align: middle;
        padding-top: 17px;
      }
      span:after{
        content:'';
        width: 100%;
        display: inline-block;
        height: 0;
      }
      width: 90px;
      margin-right: 10px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .small {
    .mint-cell-title {
      font-size: 14px!important;
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .checkbox-item {
    margin: 0 10px;
  }
}
</style>

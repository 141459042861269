<template>
  <div class="bind-phone-page">
    <div class="logo-box">
      <img class="logo-img-mingyu" alt="Vue logo" src="@/assets/logo_2.png">
      <p>
        艾尔兴近视防控
      </p>
      <p>
        积分计划平台
      </p>
    </div>
    <div class="form-box" :style="`margin-top:${marginTop}px`">
      <!-- <div class="bind-phone-input" >
        <label class="input-label" for="idCode"><img style="width: 25px" src="../../assets/icon_from.png" /></label>
        <select v-model="fromState">
          <option :value="state" v-for="state in froms" :key="state">{{state}}</option>
        </select>
      </div> -->
      <!-- <div class="bind-phone-input" >
        <label class="input-label" for="idCode"><img style="width: 25px" src="../../assets/icon_card.png" /></label>
        <input @focus="active=true" @blur="active=false" placeholder="身份证号" id="idCode" type="text" v-model="idCode" />
      </div> -->
      <div class="bind-phone-input" >
        <label class="input-label" for="mobile"><img src="../../assets/icon_phone.png" /></label>
        <input @focus="active=true" @blur="active=false" maxlength="11" placeholder="手机号" id="mobile" type="tel" v-model="mobile" />
      </div>
      <div class="bind-phone-input" >
        <label class="input-label" for="smsCode"><img style="width: 20px" src="../../assets/icon_code.png" /></label>
        <input @focus="active=true" @blur="active=false" maxlength="6" placeholder="验证码" id="smsCode" type="tel" v-model="smsCode" />
        <mt-button class="code-btn" @click="sendSMS" @disabled="TIME_COUNT !== 60" plain type="primary">{{ TIME_COUNT !== 60 ? TIME_COUNT : '获取验证码' }}</mt-button>
      </div>
      <!--<div class="bind-phone-switch">
        <label>现实世界科学研究：</label>
        <mt-switch v-model="isRealDiscuss"></mt-switch>
      </div>-->
      <mt-button class="bind-phone-btn" @click="bindMobile" type="primary">确定绑定</mt-button>
      <p>登录注册表示同意用户协议、隐私条款</p>
    </div>
    <div v-if="show" class="qa-mask" >
      <div class="qa-dialog" @click="() => show = false">
        <div class="qa-dialog-content">
        <div class="qa-dialog-close">
          <img src="../../assets/close.png" />
        </div>
          <div class="qa-dialog-p">亲爱的用户，您还没开通艾尔兴仪器登录账号，请联系客服开通
          </div>
          <div class="qa-dialog-img">
            <img src="../../assets/bind_phone_qrcode.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'mint-ui'
const config = window.config
export default {
  name: 'bind-phone-page',
  data() {
    return {
      froms: [
        '中山眼科中心',
        '项目合作医院',
        '医生病人推荐'
      ],
      fromState: '',
      mobile: '',
      idCode: '',
      show: false,
      smsCode: '',
      isRealDiscuss: false,
      timer: null,
      TIME_COUNT: 60,
      marginTop: 300
    }
  },
  mounted () {
    this.marginTop = document.documentElement.clientHeight * 0.4
  },
  methods: {
    sendSMS () {
      const setTimer = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.TIME_COUNT > 0) {
            this.TIME_COUNT--
            setTimer()
          } else {
            this.TIME_COUNT = 60
          }
        }, 1000)
      }
      if (!this.mobile) {
        return Toast({
          message: '请填写手机号',
          duration: 3000
        })
      }
      if (this.TIME_COUNT !== 60) {
        return Toast({
          message: `请在${this.TIME_COUNT}秒后重新发送`,
          duration: 3000
        })
      }
      this.TIME_COUNT--
      setTimer()
      this.$store.httpDispatch({})('sendMingyuSMS', {
        mobile: this.mobile
      })
      .then((res) => {
        if (res.data.code === 0) {
          Toast({
            message: '发送成功',
            duration: 3000
          })
        }
      })
      .catch((err) => {
        Toast({
          message: `${err.data.code}: ${err.data.msg}`,
          duration: 5000
        })
      })
    },
    bindMobile () {
      if (!this.mobile) {
        return Toast({
          message: '请填写手机号',
          duration: 3000
        })
      }
      if (!this.smsCode) {
        return Toast({
          message: '请填写验证码',
          duration: 3000
        })
      }
      // if (!this.idCode) {
      //   return Toast({
      //     message: '请填写身份证号码',
      //     duration: 3000
      //   })
      // }
      // if (!this.fromState) {
      //   return Toast({
      //     message: '请选择入组来源',
      //     duration: 3000
      //   })
      // }
      this.$store.httpDispatch({})('bindMobile', {
        mobile: this.mobile,
        smsCode: this.smsCode,
        isRealDiscuss: this.isRealDiscuss,
        channel: this.fromState,
        fromPlatform: config.fromPlatform
      }).then((res) => {
        if (res.data.code === 0) {
          this.$store.httpDispatch({})('getUserInfo').then((res) => {
            const { code, data } = res.data
            if (code === 0) {
              this.$store.dispatch('setUser', { user: data.user })
              this.$router.push('/')
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      })
      .catch((err) => {
        if (err.data.code === 4090010) {
          return this.show = true
        }
        Toast({
          message: `${err.data.code}: ${err.data.msg}`,
          duration: 5000
        })
      })
    }
  }
}
</script>
<style lang="less">
.bind-phone-page {
  box-sizing: border-box;
  padding: 0 40px 0;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/bind_phone_background.png');
  background-size: cover;
  .qa-mask {
    background: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .qa-dialog {
    .qa-dialog-content {
      text-align: left;
      background: #fff;
      color: #333;
      width: 80vw;
      margin: 150px auto 30px;
      padding: 20px 20px 10px;
      box-sizing: border-box;
    }
    .qa-dialog-close {
      position: relative;
      top: -70px;
      right: -30px;
      img {
        float: right;
        width: 50px;
      }
    }
    .qa-dialog-img {
      img {
        width: 100%
      }
    }
  }

  p {
    color: #54A7D8;
    // position: fixed;
    // bottom: 5px;
    left: 0;
    right: 0;
    margin: 35px 0 0 0;
  }
  .logo-box {
    position: relative;
    top: 100px;
    img {
      width: 50%;
      margin-bottom: 50px;
    }
    p {
      color: #2680CC;
      margin: 10px 0 0 0;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .logo-img-mingyu {
    width: 100%;
    /* margin-top: 30px; */
    position: relative;
  }
  .bind-phone-switch{
    background: none;
    color:#fff;
    text-align: left;
    padding: 15px 0;
    border-bottom: 1px solid #198ACB;
    label {
      display: inline-block;
    }
    .mint-switch {
      float: right;
    }

  }
  .bind-phone-btn {
    margin-top: 30px;
    height: 35px;
    line-height: 35px;
    width: 100%;
    background: #fff;
    color: #007FC2;
    border-radius: 100px;
    font-weight: 900;
  }
  .form-box {
    .bind-phone-input {
      width: 100%;
      border-bottom: 1px solid #198ACB;
      color: #F29F19;
      text-align: left;
      display: flex;
      vertical-align: bottom;
      padding-top: 15px;
      .input-label {
        width: 20px;
      }
      img {
        width: 18px;
      }
      input {
        letter-spacing: 2px;
        flex: 1;
        width: 100px;
        background: none;
        font-size: 16px;
        padding-left: 10px;
        height: 35px;
        color: #F29F19;
        position: relative;
        bottom: 5px;
        border: 0;
      }
      select {
        background:  none;
        border: none;
        width: 100%;
        color: #F29F19;
        padding-left: 10px;
      }
      input::-webkit-input-placeholder {
        color: #F29F19;
      }
      input::-moz-input-placeholder {
        color: #F29F19;
      }
      input::-ms-input-placeholder {
        color: #F29F19;
      }
      .code-btn {
        position: relative;
        top: 5px;
        line-height: 25px;
        height: min-content;
        background: #fff;
        color: #007FC2;
        border-radius: 100px;
        font-size: 13px;
        font-weight: 900;
        padding: 0 10px;
      }
    }
  }
}
</style>



<template>
  <div>
    <div class="report-box" :id="ruleForm._id" >
    <div class="report" style="  ">
      <img src="@/assets/logo_2.png" style="width: 200px;margin-top: 20 0;float: left;" alt="">
      <h3 style="width: 220px;float:right;">编号：{{ruleForm.code}}</h3>
      <h1 v-if="ruleForm.receiptType === '费用收据'" style="margin-top:50px;text-align: center;color: #333;font-size: 35px;">费用收据</h1>
      <h1 v-if="ruleForm.receiptType === '押金收据'" style="margin-top:50px;text-align: center;color: #333;font-size: 35px;">押金收据</h1>
      <p>
        今天收到 <span class="underline">{{ruleForm.name || '    '}}</span>交来
        <span v-if="ruleForm.receiptType === '费用收据'">青少年视力数据管理费用</span>
        <span v-if="ruleForm.receiptType === '押金收据'">艾尔兴仪器押金</span>
      </p>
      <div class="receipt-table">
        <div class="receipt-td" style="width: 30%;border-right: 0;">
          <div class="receipt-tr">人民币</div>
          <div class="receipt-tr">(金额大写)</div>
        </div>
        <div class="receipt-td" style="line-height: 60px; text-align: center; width: 40%;border-right: 0;">
          {{numberParseChina(ruleForm.price)}}
        </div>
        <div class="receipt-td td-money">
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">万</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 0)}}</div>
        </div>
        <div class="receipt-td td-money" >
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">千</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 1)}}</div>
        </div>
        <div class="receipt-td td-money" >
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">百</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 2)}}</div>
        </div>
        <div class="receipt-td td-money" >
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">十</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 3)}}</div>
        </div>
        <div class="receipt-td td-money" >
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">元</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 4)}}</div>
        </div>
        <div class="receipt-td td-money" >
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">角</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 5)}}</div>
        </div>
        <div class="receipt-td td-money"  style="border-right: 1px solid #333;">
          <div class="receipt-tr" style="border-bottom: 1px solid #333;">分</div>
          <div class="receipt-tr">{{getNumber(ruleForm.price, 6)}}</div>
        </div>
      </div>
      <br>
      <div v-if="ruleForm.receiptType === '费用收据'">
        <p v-if="ruleForm.isNeedReturn">
          * 在使用期满后，请按时返还仪器。
        </p>
      </div>
      <div v-if="ruleForm.receiptType === '押金收据'">
        <h2>需凭仪器及本收据退押金。</h2>
        <h2>仪器编号：{{ ruleForm.deviceCode || '' }}</h2>
        <p>
          * 在使用过程中，如有任何疑问，由广东艾尔兴服务中心负责解释。
        </p>
        <p v-if="ruleForm.isNeedReturn">
          * 在使用期满后，请按时返还仪器，不按时返还或不办理继续使用手续的，逾期按每天100元扣减押金。
        </p>
        <p>
          详询艾尔兴广东服务中心 020-87600696。
        </p>
      </div>
      <div style="margin-top: 30px">
        <div style="display:inline-block; position: relative;width: 100%;">
          <div style="height: 40px; text-align: left;">
            <span>
            经办人: <span class="underline">{{ruleForm.staffname || '    '}}</span>
            </span>
            <span style="margin-left: 10%;"> {{ getDate(new Date()) }}</span>
            <span style="display: inline-block; margin-left: 15%;;">
              广东艾尔兴服务中心 <br>
              广州明煜视觉科技有限公司（盖章）
            </span>
          </div>
           <img style="position:absolute;width: 20%;top: -80px;right: 10%;" alt="Vue logo" src="@/assets/receiptsign.png">
        </div>
      </div>
      <p>
      </p>
    </div>
    </div>
  </div>
  </template>
  <script>
  import moment from 'moment'
  import html2canvas from 'html2canvas'
  import oss from '../../utils/oss'
  export default {
    props: ['ruleForm', 'print'],
    data() {
      return  {
        dataUrl: ''
      }
    },
    beforeMount () {
    },
    methods: {
      getNumber (money, index) {
        if (!money) {
          return '0'
        }
        money = money.toString()
        let arr = money.split('.')
        const ints = arr[0].split('')
        const floats = arr[1] ? arr[1].split('') : []
        while (ints.length < 5) {
          ints.unshift('0')
        }
        while (floats.length < 2) {
          floats.push('0')
        }
        const moneyarr = ints.concat(floats)
        if (moneyarr[index]) {
          if (index == 0 && moneyarr[index] === '0') {
            return '¥'
          }
          return moneyarr[index]
        }
        return '0'
      },
      numberParseChina (money) {
        //汉字的数字
        var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
        //基本单位
        var cnIntRadice = ['', '拾', '佰', '仟'];
        //对应整数部分扩展单位
        var cnIntUnits = ['', '万', '亿', '兆'];
        //对应小数部分单位
        var cnDecUnits = ['角', '分', '毫', '厘'];
        //整数金额时后面跟的字符
        var cnInteger = '整';
        //整型完以后的单位
        var cnIntLast = '圆';
        //最大处理的数字
        var maxNum = 999999999999999.9999;
        //金额整数部分
        var integerNum;
        //金额小数部分
        var decimalNum;
        //输出的中文金额字符串
        var chineseStr = '';
        //分离金额后用的数组，预定义
        var parts;
        if (money == '') {
            return '';
        }
        money = parseFloat(money);
        if (money >= maxNum) {
            //超出最大处理数字
            return '';
        }
        if (money == 0) {
            chineseStr = cnNums[0] + cnIntLast + cnInteger;
            return chineseStr;
        }
        //转换为字符串
        money = money.toString();
        if (money.indexOf('.') == -1) {
            integerNum = money;
            decimalNum = '';
        } else {
            parts = money.split('.');
            integerNum = parts[0];
            decimalNum = parts[1].substr(0, 4);
        }
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
            var zeroCount = 0;
            var IntLen = integerNum.length;
            for (var i = 0; i < IntLen; i++) {
                var n = integerNum.substr(i, 1);
                var p = IntLen - i - 1;
                var q = p / 4;
                var m = p % 4;
                if (n == '0') {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseStr += cnNums[0];
                    }
                    //归零
                    zeroCount = 0;
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if (m == 0 && zeroCount < 4) {
                    chineseStr += cnIntUnits[q];
                }
            }
            chineseStr += cnIntLast;
        }
        //小数部分
        if (decimalNum != '') {
            var decLen = decimalNum.length;
            for (var i = 0; i < decLen; i++) {
                var n = decimalNum.substr(i, 1);
                if (n != '0') {
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (chineseStr == '') {
            chineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (decimalNum == '') {
            chineseStr += cnInteger;
        }
        return chineseStr;
      },
      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      async uploadBlobToOSS (imgBase64) {
        this.uploadLoading = true
        try {
          this.sts = await oss.getSTS()
        } catch (err) {
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
        const id = new Date().getTime()
        const blob = this.dataURLtoBlob(imgBase64)
        try {
          const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
          this.uploadLoading = false
          this.$store.httpDispatch({})('saveAgreement', {
            _id: this.ruleForm._id,
            imageUrl: result.url
          }).then((res) => {
            const { code } = res.data
            this.uploadLoading = false
          }).catch((err) => {
            console.log(err.data)
            this.uploadLoading = false
          })
        } catch (err) {
          this.uploadLoading = false
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
      },
      getDate (createdAt) {
        if (!createdAt) {
          return '/'
        }
        return moment(createdAt).format('YYYY年MM月DD日')
      }
    }
  }
  </script>
  <style lang="less" scoped>
  @media (max-width: 900px) {
    .report-box {
      overflow: auto;
      width: 100vw;
      height: 100vh;
    }
    h2 {
      margin: 5px 0;
    }
    .report {
      box-sizing: border-box;
      padding: 0 5px 0;
      width: 850px;
      background: #fff;
      float: left;
    }
  }
  .report {
    padding: 50px 30px 100px;
    .underline {
      font-weight: 800;
      text-align: center;
      border-bottom: 1px solid #777;
      display: inline-block;
      min-width:50px;
    }
    p {
      font-size: 13px;
      margin: 5px 0;
      line-height: 22px;
    }
    h3 {
      font-size: 15px;
      margin: 7px 0;
    }
    .receipt-table {
      width: 100%;
    }
    .receipt-table .receipt-td {
      vertical-align: top;
      padding: 0 0;
      border: 1px solid #333;
      height: 60px;
      display: inline-block;
    }
    .receipt-table .td-money {
      width: 20px;
      border-right:  0;
    }
    .receipt-table .receipt-td .receipt-tr{
      padding: 2px 2px;
      box-sizing: border-box;
      width: 100%;
      display: block;
      text-align: center;
    }
    footer {
      text-align: center;
      width: 100%;
    }
  }
  </style>
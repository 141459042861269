

<template>
  <div>
    <div class="html-report" :id="ruleForm._id" style="  ">
      <img  src="@/assets/logo_2.png" style="width: 200px;margin-top: 20 0;" alt="">
      <h3 style="width: 220px;float:right;">编号：{{ruleForm.code}}</h3>
      <!-- <h1 style="margin-top:50px;text-align: center;color: #2889CF;font-size: 35px;">{{model.title}}</h1> -->
      <div v-html="formhtml" id="editor-content-view" class="editor-content-view">
      </div>
      <div style="margin-top: 30px">
        <div style="display:inline-block;width:50%;position: relative;">
          <div style="height: 40px">
            <span>
               甲方盖章：
            </span>
          </div>
          <div style="margin-top: 30px"  v-if="ruleForm.signatureUrl">
            日期: <span class="underline" v-if="ruleForm.signAt">{{getDate(ruleForm.signAt)}}</span>
          </div>
          <div style="margin-top: 30px" v-else>
            日期: ___年___月__日
          </div>
          <img  style="position:absolute;width: 40%;top: 0px;left: 20%;" alt="Vue logo" src="@/assets/printsign.png">
        </div>
        <div style="display:inline-block;width:50%;position: relative">
          <div style="height: 40px">
          <span>
          乙方签字：
         </span>
          <span>
              <img  v-if="ruleForm.signatureUrl" style="height: 40px;vertical-align: middle"
              crossOrigin="Anonymous"
              :src="ruleForm.signatureUrl + '?_now_='+new Date().getTime()" alt="">
          </span>
          </div>
          <div style="margin-top: 30px"  v-if="ruleForm.createdAt">
            日期: <span class="underline" v-if="ruleForm.createdAt">{{getDate(ruleForm.createdAt)}}</span>
          </div>
          <div style="margin-top: 30px" v-else>
            日期: ___年___月__日
          </div>
        </div>
      </div>
      <p>
      </p>
    </div>
  </div>
  </template>
  <script>
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import moment from 'moment'
  import _ from 'lodash'
  import oss from '../../utils/oss'
  export default {
    props: ['ruleForm', 'model', 'print'],
    components: {Editor},
    data() {
      return  {
        sts: {},
        dataUrl: ''
      }
    },
    computed: {
      formhtml: {
        get () {
          let originHtml = _.cloneDeep(this.model.html)
          const keys = this.model.inputs.map((item) => item.key)
          console.log(keys)
          keys.map((key) => {
            let input = null
            this.model.inputs.map((item) => {
              if (key == item.key) {
                input = item
              }
            })
            if (input && input.type == 'checkbox') {
              originHtml = originHtml.replace(`{{${key}}}`, `<input type="checkbox" disabled ${this.ruleForm[key] == true ? 'checked' : ''}> `)
            }
            if (input && (input.type == 'input' || input.type == 'select')) {
              if (!this.ruleForm[key]) {
                originHtml = originHtml.replace(`{{${key}}}`, `<span class="underline">/</span>`)
              } else {
                originHtml = originHtml.replace(`{{${key}}}`, `<span class="underline">${this.ruleForm[key]}</span>`)
              }
            }
            if (input && input.type == 'date') {
              if (!this.ruleForm[key]) {
                originHtml = originHtml.replace(`{{${key}}}`, `<span class="underline">/</span>`)
              } else {
                originHtml = originHtml.replace(`{{${key}}}`, `<span class="underline">${this.getDate(this.ruleForm[key])}</span>`)
              }
            }
          })
          return originHtml
        },
        set () {}
      },
    },
    beforeMount () {
    },
    methods: {
      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      async uploadBlobToOSS (imgBase64) {
        this.uploadLoading = true
        try {
          this.sts = await oss.getSTS()
        } catch (err) {
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
        const id = new Date().getTime()
        const blob = this.dataURLtoBlob(imgBase64)
        try {
          const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
          this.uploadLoading = false
          this.$store.httpDispatch({})('saveAgreement', {
            _id: this.ruleForm._id,
            imageUrl: result.url
          }).then((res) => {
            const { code } = res.data
            this.uploadLoading = false
          }).catch((err) => {
            console.log(err.data)
            this.uploadLoading = false
          })
        } catch (err) {
          this.uploadLoading = false
          this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
        }
      },
      getDate (createdAt) {
        if (!createdAt) {
          return '/'
        }
        return moment(createdAt).format('YYYY年MM月DD日')
      }
    }
  }
  </script>
  <style lang="less">
  @media (max-width: 767px) {
    .html-report {
      padding:  0 0!important;
    }

  }
  .html-report {
    padding: 50px 30px;
    .underline {
      // text-decoration:underline;
      font-weight: 800;
      border-bottom: 1px solid #777;
      display: inline-block;
      text-align: center;
      min-width:50px;
    }
    .checked {
       width: 12px;
       vertical-align: top;
       position: relative;
       top: 4px;
    }
    .bold {
      font-size: 22px;
      font-weight: 800;
    }
    .fk {
      font-size: 20px;
    }
    p {
      font-size: 13px;
      margin: 5px 0;
      line-height: 22px;
    }
    h3 {
      font-size: 15px;
      margin: 7px 0;
    }
    table td
    {
      width: 200px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      // border: 1px solid black;
    }
    footer {
      text-align: center;
      width: 100%;
    }
  }
  </style>
<template>
  <div >
    <div class="points-page">
      <img v-if="getDate(user.transferAt)" class="points_page_img" src="../../assets/after920.jpg"/>
      <img v-if="!getDate(user.transferAt)" class="points_page_img" src="../../assets/before920.jpg"/>
      <mt-button class="visit-btn" @click="$router.push('/')" type="primary">返回</mt-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'points-page',
  data() {
    return {
      activeIndex: null,
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) return false
      if (moment(createdAt).isAfter(moment('2023-09-20 00:00:00'))) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>
<style lang="less">
.points-page {
  background: #CECECE;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  .points_page_img {
    width: 100vw;
  }
  .visit-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
}
</style>

<template>
  <div>
  <div class="home-page" v-if="fromPlatform !== 'mingyu'">
    <div @click="$router.push('/userinfo')" class="user-cell user-info">
      <img class="headimgurl" slot="icon" :src="`${user.headimgurl}`">
      <div>
        <div class="user-title">
          <span>{{user.nickname}}
            <img v-if="user.sex === 1" src="../../assets/icon_male.png" alt="">
            <img v-else src="../../assets/icon_female.png" alt="">
          </span>
        </div>
        <div class="user-mobile"><span>手机号：{{dataDesensitization(user.mobile)}} </span></div>
      </div>
      <img class="icon-right" src="../../assets/icon_right.png" alt="">
    </div>
    <div class="btn-plane">
      <div class="plane-body">
        <div class="body-div">
          <router-link to="/uploaddata">
            <div>
              <img src="../../assets/img_upload_data.png" />
              <p>数据上传</p>
            </div>
          </router-link>
          <router-link to="/visits">
            <div>
              <img src="../../assets/img_visits.png" />
              <p>健康档案</p>
            </div>
          </router-link>
        </div>
        <div class="body-div">
          <router-link to="/userinfo">
            <div>
              <img src="../../assets/img_userinfo.png" />
              <p>资料修改</p>
            </div>
          </router-link>
          <router-link to="/record">
            <div>
              <img src="../../assets/img_visits.png" />
              <p>使用情况</p>
            </div>
          </router-link>
        </div>
        <div v-if="user.planId" class="body-div">
          <router-link v-if="!user.deposit_payment" to="/deposit">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>充值/续费</p>
            </div>
          </router-link>
          <router-link v-else to="/pay">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>充值</p>
            </div>
          </router-link>
          <router-link to="/payments">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>支付记录</p>
            </div>
          </router-link>
        </div>
        <div class="body-div">
          <router-link to="/points">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>我的积分</p>
            </div>
          </router-link>
          <router-link to="/coupon">
            <div>
              <img src="../../assets/code.png" />
              <p>串码输入</p>
            </div>
          </router-link>
        </div>
        <div class="body-div" v-if="user.planId">
          <router-link  to="/coupons">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>优惠券</p>
            </div>
          </router-link>
          <a href="#" style="opacity: 0"></a>
        </div>
        <div v-if="user.card_name && user.endDate" class="body-end-date">
          <p>套餐终止日期：</p>
          <p>
            {{ getDate(user.endDate) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="home-page" v-else>
    <div class="user-cell user-info">
      <img class="headimgurl" slot="icon" :src="`${user.headimgurl}`">
      <div>
        <div class="user-title">
          <span>{{user.nickname}}
            <img v-if="user.sex === 1" src="../../assets/icon_male.png" alt="">
            <img v-else src="../../assets/icon_female.png" alt="">
          </span>
        </div>
        <div class="user-mobile"><span>手机号：{{dataDesensitization(user.mobile)}} </span></div>
      </div>
    </div>
    <div class="btn-plane">
      <div class="plane-body">
        <div class="body-div">
          <router-link to="/points">
            <div>
              <img src="../../assets/img_user_points.png" />
              <p>我的积分</p>
            </div>
          </router-link>
          <router-link to="/gift">
            <div>
              <img src="../../assets/img_gift.png" />
              <p>积分兑换</p>
            </div>
          </router-link>
        </div>
        <div class="body-div">
          <router-link to="/record">
            <div>
              <img src="../../assets/img_visits.png" />
              <p>使用情况</p>
            </div>
          </router-link>
          <a href="#" style="opacity: 0"></a>
        </div>
        <div v-if="user.card_name && user.endDate" class="body-end-date">
          <p>套餐终止日期：</p>
          <p>
            {{ getDate(user.endDate) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
import { mapState } from 'vuex'
import moment from 'moment'
const config = window.config
export default {
  name: 'home-page',
  data() {
    return {
      mobile: '',
      smsCode: '',
      fromPlatform: config.fromPlatform
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    console.log(this.$route)
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD')
    },
    dataDesensitization (num) {
      if (!num) return ''
      var len = num.length
      if(len && len > 4) {
        return num.substring(0, 3) + '****' + num.substring(len - 4, len)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home-page {
  padding: 20px 20px 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  .user-cell {
    text-align: left;
    padding: 20px 20px;
    border-radius: 10px;
  }
  .user-info {
    background: #fff;
    display: flex;
    position: relative;
    height: 50px;
    img.headimgurl {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .icon-right {
      position: absolute;
      right: 10px;
      width: 10px;
      top: 35px;
    }
    .user-title {
      img {
        width: 10px;
        height: auto;
      }
      font-size: 18px;
    }
    .user-mobile {
      font-size: 15px;
    }
  }
  .btn-plane {
    .body-end-date {
      text-align: left;
    }
    .plane-body {
      background: #fff;
      margin-top: 20px;
      box-sizing: border-box;
      padding: 30px 40px;
      border-radius: 10px;
      .div-one {
        width: 50%;
        a {
          margin-right: 5px!important;
        }
      }
      .body-div {
        display: flex;
        margin-top: 5px;
        a {
          color: #333;
          text-decoration: none;
          border: 1px solid #E1EDF1;
          border-radius: 5px;
          padding-top: 15px;
          flex: 1;
          padding-bottom: 15px;
        }
        p {
          margin: 0 0;
        }
        a:link {
          text-decoration: none;
        }
        a:visited {
          text-decoration: none;
        }
        a:hover {
          text-decoration: none;
        }
        a:active {
          text-decoration: none;
        }
        img {
          width: 50%;
        }
        a:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>

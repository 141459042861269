<template>
  <div class="revisit-page">
    <a data-v-426df720="" class="mint-cell mint-field">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">1、治疗过程中有无特殊不适（每次治疗后15min以后的不适）</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.isDiscomfort" value="true" id="isDiscomforttrue" name="isDiscomfort" />
            <label for="isDiscomforttrue">是</label>
          </div>
          <div class="female">
            <input type="radio" v-model="form.isDiscomfort" value="false" id="isDiscomfortfalse" name="isDiscomfort" />
            <label for="isDiscomfortfalse">否</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <mt-field v-if="form.isDiscomfort === 'true'" label="如何不适" type="text" class="" v-model="form.discomfortRemark"></mt-field>
    <mt-field label="2、每周户外活动时间多少小时" class=""  type="text" v-model="form.outdoorActivityTime"></mt-field>
    <mt-field label="3、平均每天在家视近活动-读书，写作业 ，玩手机/Pad的时间" class=""  type="text" v-model="form.mobileTime"></mt-field>
    <a data-v-426dfsd20="" class="mint-cell mint-field ">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">4、是否坚持一周至少使用10次或以上</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.isOver10" value="true" id="isOver10true" name="isOver10" />
            <label for="isOver10true">是</label>
          </div>
          <div class="female">
            <input type="radio" v-model="form.isOver10" value="false" id="isOver10false" name="isOver10" />
            <label for="isOver10false">否</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>

    <mt-button style="margin-top: 50px" class="user-btn" @click="submit" type="primary">提交</mt-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import _ from 'lodash'
const defaultForm = {
  id: '',
  isDiscomfort: '',
  discomfortRemark: '',
  outdoorActivityTime: '',
  mobileTime: '',
  isOver10: ''
}
export default {
  name: 'user-info-page',
  data() {
    return {
      form: _.cloneDeep(defaultForm),
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.form = Object.assign({}, this.form)
  },
  methods: {
    submit () {
      const body = Object.assign({}, this.form)
      body.id = this.$route.query.id
      this.$store.httpDispatch({})('updateVisitInfo', body).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          Toast({
            message: '提交成功',
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less">
.revisit-page {
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 50px;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 100px;
      top: 5px;
      color: red;
    }
  }
  .required-long {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 160px;
      top: 5px;
      color: red;
    }
  }
  .city-picker {
    .mint-cell-value {
      div {
        font: 400 11px system-ui;
        font-size: inherit;
        width: 80px;
        border: 0;
      }
    }
  }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      display: block;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        text-align: left;
        width: 100%;
        vertical-align: middle;
        line-height: 40px;
      }
      width: 100%;
      margin-right: 20px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .checkbox-item {
    margin: 0 10px;
  }
  .male {
    display: inline-block;
    margin: 0 20px 0 10px;
    input[type="radio"] + label::before {
      border: 1px solid #017ECB;
    }
    input[type="radio"]:checked + label::before {
      background-color: #017ECB;
    }
  }
  .female {
    display: inline-block;
    input[type="radio"] + label::before {
      border: 1px solid #E293B3;
    }
    input[type="radio"]:checked + label::before {
      background-color: #E293B3;
    }
  }
  .sex input[type="radio"] + label::before {
    content: "\a0"; /*不换行空格*/
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 1em;
    height: 1em;
    margin-right: .4em;
    border-radius: 50%;
    text-indent: .15em;
    line-height: 1;
  }
  .sex input[type="radio"]:checked + label::before {
    width: 0.6em;
    height: 0.6em;
    background-clip: content-box;
    padding: .2em;
  }
  .sex input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}
</style>

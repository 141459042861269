<template>
  <div class="user-return-page">
    <h2>满意度调查问卷</h2>
    <div class="info">
        为了提升客服服务质量，请对客服服务工作进行星级评分。  5☆为很满意；4☆为满意；3☆为一般；2☆为不满；1☆为很不满
    </div>
    <div class="return-item">
        <div class="title-item">疑问解答 数据分析</div>
        <star-rating class="star" @rating-selected="setQaRating"></star-rating>
    </div>
    <div class="return-item">
        <div class="title-item">仪器介绍 眼轴检查</div>
        <star-rating  class="star" @rating-selected="setExamRating"></star-rating>
    </div>
    <div class="return-item">
        <div class="title-item">综合评分</div>
        <star-rating  class="star" @rating-selected="setToatalRating"></star-rating>
    </div>
    <div class="return-item">
       <mt-field label="宝贵意见" type="text" v-model="form.suggestion"></mt-field>
    </div>
    <div class="info">感谢家长对我们客服服务质量进行评分，您的宝贵意见及评分是我们前进的动力</div>
    <mt-button class="user-btn" style="margin-top: 20px;" @click="submit" type="primary">提交</mt-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
export default {
  name: 'user-return-page',
  data() {
    return {
      form: {
        bookingId: this.$route.query.id,
        suggestion: '',
        qa_rating: 0,
        exam_rating: 0,
        total_rating: 0,
      },
      popupVisible: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
  },
  methods: {
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    setQaRating(rating){
      this.form.qa_rating = rating
    },
    setExamRating(rating){
      this.form.exam_rating = rating
    },
    setToatalRating(rating){
      this.form.total_rating = rating
    },
    submit () {
      console.log(this.form)
      const body = Object.assign({}, this.form)
      this.$store.httpDispatch({})('returnBooking', body).then((res) => {
        const { code, data, msg } = res.data
        if (code === 0) {
          Toast({
            message: '提交成功',
            duration: 3000
          })
        } else {
          Toast({
            message: msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
        const { data } = err
        Toast({
          message: data.msg || '网络错误',
          duration: 3000
        })
      })
    }
  }
}
</script>
<style lang="less">
.user-return-page {
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 50px;
  height: 120vh;
  background: #eee;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .info {
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #999;
  }
  .return-item {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #999;
    .star {
        margin-left: 10%;
    }
  }

  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 100px;
      top: 5px;
      color: red;
    }
  }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        width: 100%;
        text-align: justify;
        vertical-align: middle;
        padding-top: 17px;
      }
      span:after{
        content:'';
        width: 100%;
        display: inline-block;
        height: 0;
      }
      width: 90px;
      margin-right: 10px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .checkbox-item {
    margin: 0 10px;
  }
}
</style>

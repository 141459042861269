<template>
  <div class="user-info-page">
    <mt-field class="required" label="孩子姓名" v-model="form.nickname"></mt-field>
    <div class="user-gender">
    </div>
    <a data-v-426df720="" class="mint-cell mint-field required"><!---->
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title"><!---->
          <span class="mint-cell-text">性别</span> <!---->
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.sex" value="male" id="male" name="sex" />
            <label for="male">男</label>
          </div>
          <div class="female">
            <input type="radio" v-model="form.sex" value="female" id="female" name="sex" />
            <label for="female">女</label>
          </div>
        </div> <!---->
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <mt-field class="required" label="出生日期" type="date" v-model="form.birthday"></mt-field>
    <mt-field class="required" disabled label="监护人姓名" type="text" v-model="form.parentName"></mt-field>
    <div class="small">
      <mt-field class="required" disabled label="监护人身份证" type="text" v-model="form.parentIdCode"></mt-field>
    </div>
    <mt-field class="required" label="监护人手机1" type="tel" v-model="form.mobile1"></mt-field>
    <mt-field label="监护人手机2" type="tel" v-model="form.mobile2"></mt-field>
    <a data-v-426df720="" class="mint-cell mint-field"><!---->
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title"><!---->
          <span class="mint-cell-text">医院</span> <!---->
        </div>
        <div class="mint-cell-value">
          <select  disabled="disabled" v-model="form.hospitalId">
            <option value="">无</option>
            <option :value="hospital._id" v-for="hospital in hospitals" :key="hospital._id">{{hospital.name}}</option>
          </select>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <!--<a data-v-426df720="" class="mint-cell mint-field">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">主治医生</span>
        </div>
        <div v-if="user.hospitalDoctorId" class="mint-cell-value">
          <select disabled="disabled" v-model="form.hospitalDoctorId">
            <option value="">无</option>
            <option :value="doctor._id" v-for="doctor in doctors.filter((doctor) => doctor.hospitalId === form.hospitalId )" :key="doctor._id">{{doctor.nickname}}</option>
          </select>
        </div>
        <div v-else class="mint-cell-value">
          <select v-model="form.hospitalDoctorId">
            <option value="">无</option>
            <option :value="doctor._id" v-for="doctor in doctors.filter((doctor) => doctor.hospitalId === form.hospitalId )" :key="doctor._id">{{doctor.nickname}}</option>
          </select>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a> --->
    <mt-field class="required" label="身份证号码" type="text" v-model="form.idCode"></mt-field>

    <a data-v-426df720="" class="mint-cell mint-field required-long">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title" style="width: 150px">
          <span class="mint-cell-text">早发低/中/高近视</span>
        </div>
        <div class="mint-cell-value">
          <div class="checkbox-item">
            <label for="low">低</label>
            <input type="radio" :disabled="user.prematureMyopia" v-model="form.prematureMyopia" value="low" id="low" name="prematureMyopia" />
          </div>
          <div class="checkbox-item">
            <label for="medium">中</label>
            <input type="radio" :disabled="user.prematureMyopia" v-model="form.prematureMyopia" value="medium" id="medium" name="prematureMyopia" />
          </div>
          <div class="checkbox-item">
            <label for="high">高</label>
            <input type="radio" :disabled="user.prematureMyopia" v-model="form.prematureMyopia" value="high" id="high" name="prematureMyopia" />
          </div>
        </div>
      </div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required-long">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title" style="width: 150px">
          <span class="mint-cell-text">是否有遗传</span>
        </div>
        <div class="mint-cell-value">
          <div class="checkbox-item">
            <label for="true">是</label>
            <input type="radio" :disabled="user.genetic" v-model="form.genetic" value="true" id="true" name="genetic" />
          </div>
          <div class="checkbox-item">
            <label for="false">否</label>
            <input type="radio" :disabled="user.genetic" v-model="form.genetic" value="false" id="false" name="genetic" />
          </div>
        </div>
      </div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required-long">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title" style="width: 150px">
          <span class="mint-cell-text">是否联合OK镜使用</span>
        </div>
        <div class="mint-cell-value">
          <div class="checkbox-item">
            <label for="true">是</label>
            <input type="radio" :disabled="user.joinOKmirror" v-model="form.joinOKmirror" value="true" id="true" name="joinOKmirror" />
          </div>
          <div class="checkbox-item">
            <label for="false">否</label>
            <input type="radio" :disabled="user.joinOKmirror" v-model="form.joinOKmirror" value="false" id="false" name="joinOKmirror" />
          </div>
        </div>
      </div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required-long">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title" style="width: 150px">
          <span class="mint-cell-text">是否联合阿托品使用</span>
        </div>
        <div class="mint-cell-value">
          <div class="checkbox-item">
            <label for="true">是</label>
            <input type="radio" :disabled="user.joinAtropine" v-model="form.joinAtropine" value="true" id="true" name="joinAtropine" />
          </div>
          <div class="checkbox-item">
            <label for="false">否</label>
            <input type="radio" :disabled="user.joinAtropine" v-model="form.joinAtropine" value="false" id="false" name="joinAtropine" />
          </div>
        </div>
      </div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field"><!---->
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title"><!---->
          <span class="mint-cell-text">现居住地</span>
        </div>
        <div @click="popupVisible = true" class="city-picker mint-cell-value">
          <div class="mint-cell-value">
            <div>{{form.province}}</div>
            <div>{{form.city}}</div>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <mt-field class="required" label="详细地址" type="text" v-model="form.address"></mt-field>
    <mt-field label="邮箱" type="email" v-model="form.email"></mt-field>
    <mt-popup
      v-model="popupVisible"
      position="bottom">
      <mt-picker valueKey="name" :slots="slots" @change="onValuesChange"></mt-picker>
    </mt-popup>
    <mt-button class="user-btn" @click="submit" type="primary">提交</mt-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import citys from '@/utils/city'
import province from '@/utils/province'
const provinceMap = {}
province.forEach(p => {
  provinceMap[p.name] = p.id
})
export default {
  name: 'user-info-page',
  data() {
    return {
      hospitals: [],
      doctors: [],
      form: {
        prematureMyopia: '',
        genetic: '',
        joinOKmirror: '',
        parentName: '',
        parentIdCode: "",
        joinAtropine: '',
        nickname: '',
        sex: '',
        birthday: '',
        mobile: '',
        idCode: '',
        address: '',
        email: '',
        city: '',
        province: '',
        hospitalId: '',
        hospitalDoctorId: '',
        mobile1: '',
        mobile2: '',
        mobile3: '',
        mobile4: ''
      },
      popupVisible: false,
      slots: [
        {
          flex: 1,
          values: province.map((p) => p.name),
          className: 'slot1',
          textAlign: 'right'
        }, {
          divider: true,
          content: '-',
          className: 'slot2'
        }, {
          flex: 1,
          values: ['市辖区'],
          className: 'slot3',
          textAlign: 'left'
        }
      ]
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.form = Object.assign({}, this.form, this.user)
    this.form.sex = this.form.sex !== 1 ? 'female' : 'male'
    this.getHospital()
    this.getHospitalDoctor()
  },
  methods: {
    getHospital () {
      this.$store.httpDispatch({})('getHospitalsName').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.hospitals = data.hospitals
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getHospitalDoctor () {
      this.$store.httpDispatch({})('getHospitalDoctors').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.doctors = data.doctors
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    onValuesChange(picker, values) {
      const pCitys = citys[provinceMap[values[0]]].map((c) => c.name)
      picker.setSlotValues(1, pCitys)
      this.form.province = values[0]
      this.form.city = values[1]
    },
    submit () {
      const body = Object.assign({}, this.form)
      if (!this.form.nickname) {
        return Toast({
          message: '请填写用户名',
          duration: 3000
        })
      }
      if (!this.form.address) {
        return Toast({
          message: '请填写详细地址',
          duration: 3000
        })
      }
      if (!this.form.sex) {
        return Toast({
          message: '请选择性别',
          duration: 3000
        })
      }
      if (!this.form.birthday) {
        return Toast({
          message: '请填写出生日期',
          duration: 3000
        })
      }
      if (!this.form.prematureMyopia) {
        return Toast({
          message: '请选择是否早发低/中/高近视',
          duration: 3000
        })
      }
      if (!this.form.genetic) {
        return Toast({
          message: '请选择是否有遗传',
          duration: 3000
        })
      }
      if (!this.form.joinOKmirror) {
        return Toast({
          message: '请选择是否联合OK镜使用',
          duration: 3000
        })
      }
      if (!this.form.joinAtropine) {
        return Toast({
          message: '请选择是否联合阿托品使用',
          duration: 3000
        })
      }
      if (!this.form.mobile1) {
        return Toast({
          message: '请填写监护人手机号',
          duration: 3000
        })
      }
      // if (!this.form.parentName) {
      //   return Toast({
      //     message: '请填写监护人姓名',
      //     duration: 3000
      //   })
      // }
      // if (!this.form.parentIdCode) {
      //   return Toast({
      //     message: '请填写监护人身份证号码',
      //     duration: 3000
      //   })
      // }
      if (!this.form.idCode) {
        return Toast({
          message: '请填写身份证号',
          duration: 3000
        })
      }
      body.sex = this.form.sex !== 'female' ? 1 : 2
      this.$store.httpDispatch({})('updateUserInfo', body).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.$store.httpDispatch({})('getUserInfo').then((res) => {
            const { code, data } = res.data
            if (code === 0) {
              Toast({
                message: '资料修改成功',
                duration: 3000
              })
              this.$store.dispatch('setUser', { user: data.user })
              this.$router.push('/')
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less">
.user-info-page {
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 50px;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 100px;
      top: 5px;
      color: red;
    }
  }
  .required-long {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 160px;
      top: 5px;
      color: red;
    }
  }
  .city-picker {
    .mint-cell-value {
      div {
        font: 400 11px system-ui;
        font-size: inherit;
        width: 80px;
        border: 0;
      }
    }
  }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        width: 100%;
        text-align: justify;
        vertical-align: middle;
        padding-top: 17px;
      }
      span:after{
        content:'';
        width: 100%;
        display: inline-block;
        height: 0;
      }
      width: 90px;
      margin-right: 10px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .small {
    .mint-cell-title {
      font-size: 14px!important;
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .checkbox-item {
    margin: 0 10px;
  }
  .male {
    display: inline-block;
    margin: 0 20px 0 10px;
    input[type="radio"] + label::before {
      border: 1px solid #017ECB;
    }
    input[type="radio"]:checked + label::before {
      background-color: #017ECB;
    }
  }
  .female {
    display: inline-block;
    input[type="radio"] + label::before {
      border: 1px solid #E293B3;
    }
    input[type="radio"]:checked + label::before {
      background-color: #E293B3;
    }
  }
  .sex input[type="radio"] + label::before {
    content: "\a0"; /*不换行空格*/
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 1em;
    height: 1em;
    margin-right: .4em;
    border-radius: 50%;
    text-indent: .15em;
    line-height: 1;
  }
  .sex input[type="radio"]:checked + label::before {
    width: 0.6em;
    height: 0.6em;
    background-clip: content-box;
    padding: .2em;
  }
  .sex input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}
</style>

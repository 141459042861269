let province = [{
  "name": "北京市",
  "id": "110000000000"
}, {
  "name": "天津市",
  "id": "120000000000"
}, {
  "name": "河北省",
  "id": "130000000000"
}, {
  "name": "山西省",
  "id": "140000000000"
}, {
  "name": "内蒙古自治区",
  "id": "150000000000"
}, {
  "name": "辽宁省",
  "id": "210000000000"
}, {
  "name": "吉林省",
  "id": "220000000000"
}, {
  "name": "黑龙江省",
  "id": "230000000000"
}, {
  "name": "上海市",
  "id": "310000000000"
}, {
  "name": "江苏省",
  "id": "320000000000"
}, {
  "name": "浙江省",
  "id": "330000000000"
}, {
  "name": "安徽省",
  "id": "340000000000"
}, {
  "name": "福建省",
  "id": "350000000000"
}, {
  "name": "江西省",
  "id": "360000000000"
}, {
  "name": "山东省",
  "id": "370000000000"
}, {
  "name": "河南省",
  "id": "410000000000"
}, {
  "name": "湖北省",
  "id": "420000000000"
}, {
  "name": "湖南省",
  "id": "430000000000"
}, {
  "name": "广东省",
  "id": "440000000000"
}, {
  "name": "广西壮族自治区",
  "id": "450000000000"
}, {
  "name": "海南省",
  "id": "460000000000"
}, {
  "name": "重庆市",
  "id": "500000000000"
}, {
  "name": "四川省",
  "id": "510000000000"
}, {
  "name": "贵州省",
  "id": "520000000000"
}, {
  "name": "云南省",
  "id": "530000000000"
}, {
  "name": "西藏自治区",
  "id": "540000000000"
}, {
  "name": "陕西省",
  "id": "610000000000"
}, {
  "name": "甘肃省",
  "id": "620000000000"
}, {
  "name": "青海省",
  "id": "630000000000"
}, {
  "name": "宁夏回族自治区",
  "id": "640000000000"
}, {
  "name": "新疆维吾尔自治区",
  "id": "650000000000"
}]
module.exports = province
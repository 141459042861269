

<template>
<div>
  <div class="report" :id="ruleForm._id" style="  ">
    <img  src="@/assets/logo_2.png" style="width: 200px;margin-top: 20 0;" alt="">
    <h3 style="width: 220px;float:right;">编号：{{ruleForm.code}}</h3>
    <h1 style="margin-top:50px;text-align: center;color: #2889CF;font-size: 35px;">艾尔兴服务协议</h1>
    <p>
      甲方姓名（甲方为使用人的监护人）：<span class="underline">{{ruleForm.name}}</span>
    </p>
    <p>
      联系电话：<span class="underline">{{ruleForm.mobile}}</span>
    </p>
    <p>
      身份证号码：<span class="underline">{{ruleForm.idCode}}</span> 地址：<span class="underline">{{ruleForm.address}}</span>
    </p>
    <p>
      使用人姓名：<span class="underline">{{ruleForm.usename || '    '}}</span> 身份证号码：<span class="underline">{{ruleForm.useIdCode}}</span>
    </p>
    <br>
    <p>
      乙方： <span class="underline">广州明煜视觉科技有限公司</span>
    </p>
    <p>
      联系方式： <span class="underline">020-87600696</span>
    </p>
    <p>
      地址： <span class="underline" style="text-align: left;">广州市天河区华夏路津滨腾越大厦南塔6楼602</span>
    </p>
    <p>
      甲、乙双方根据《中华人民共和国民法典》相关规定，在平等、自愿、信任的基础上，就乙方向甲方提供租赁服务事宜，签订本协议如下，双方共同遵守。
    </p>
    <h3>第一条  服务基础
    </h3>
    <p>
      甲方在同时满足下列条件后，方可与乙方签订本协议。
    </p>
    <p>
      1、甲方需带使用人到医院眼科进行详细的眼部检查，将真实的数据告知乙方，并提供相关检查/检验/检测报告给乙方。眼部检查至少应包括：
    </p>
    <p>
      ①进行医学验光，测量双眼的屈光度数、眼压和裸眼视力(戴眼镜者要查矫正视力）。
    </p>
    <p>
      ②使用眼生物测量仪或IOL-MASTER等仪器测量眼轴长度、角膜曲率。
    </p>
    <p>
      ③检查眼位、眼底，确保眼底无器质性病变。
    </p>
    <p>
      2、乙方已告知甲方艾尔兴近视弱视综合治疗仪适用范围及禁忌症，甲方确认使用人非禁忌症患者，符合适用范围，可以使用艾尔兴近视弱视综合治疗仪。
    </p>
    <p>
      ①艾尔兴适应症：可适用于近视辅助治疗；可适用于屈光不正性、屈光参差性及斜视性弱视患者。使用年龄3~16岁或遵医嘱。
    </p>
    <p>
      ②艾尔兴禁忌症：1)有光过敏史的患者。2)眼底视网膜病变、白内障或其他眼内疾病的患者。3)视神经受损或先天性视神经功能障碍的患者。
    </p>
    <p>
      ③光晕症患者、多动症患者和银屑病患者禁止使用本产品，视神经病变患者和高眼压患者在医生指导下使用本产品3光晕症患者、多动症患者和银屑病患者禁止使用本产品，视神经病变患者和高眼压患者在医生指导下使用本产品
    </p>
    <p>
      3、使用人在使用艾尔兴近视弱视综合治疗仪之前，从未使用过其他通过光照来辅助近视治疗的产品。
    </p>
    <h3>
      第二条  服务事项
    </h3>
    <p>
      1、乙方向甲方提供以下服务：
    </p>
    <p>（1）设备租赁服务，租赁设备信息见下表：</p>
    <table border="1" style=" border-collapse:collapse;margin: 0 auto;">
      <tr>
        <td >租赁设备名称</td>
        <td >规格/型号</td>
      </tr>
      <tr>
        <td >艾尔兴近视弱视综合治疗仪</td>
        <td ><span class="underline">{{ruleForm.deviceType}}</span></td>
      </tr>
    </table>
    <p>
    ①设备交付：
    </p>
    <p>
    <img  class="checked" v-if="ruleForm.transferType ==='邮寄'" src="@/assets/checked.png" alt="">
    <span v-else class="fk">□</span>乙方将在本协议签订后两个工作日内将设备及使用方法、注意事项等一并以快递方式寄送给甲方；
    </p>
    <p>
    <img  class="checked" v-if="ruleForm.transferType ==='线下交付'" src="@/assets/checked.png" alt="">
    <span v-else class="fk">□</span>
    乙方已于<span class="underline">{{getDate(ruleForm.sentDate)}}</span>将设备及使用方法、注意事项等一并交付给甲方，无需另行交付。
    </p>
    <p>
      ②设备归还：甲方应当在服务期满/终止本协议之日起七天内将设备及礼品（如有）送至乙方业务点或者邮寄至乙方指定收货地址（相关运费由甲方承担）。如有逾期，应按日向乙方支付违约金100元。
    </p>
    <p>
      （2）客服服务，包括租赁设备维修、更换服务；租赁设备使用、指导服务；租赁设备使用反馈追踪服务等。乙方配备了专业的客服人员，提供客服服务。
    </p>
    <div>
      <p style="">
        2、服务期限合计<span class="underline">{{ruleForm.totalMonths}}</span>个月，其中有偿服务期限：<span class="underline">{{ruleForm.payMonths}}</span>个月，自<span class="underline">{{getDate(ruleForm.payDateStartDate)}}</span>至<span class="underline">{{getDate(ruleForm.payDateEndDate)}}</span>止。
        赠送服务期限：<span class="underline">{{ruleForm.freeMonths}}</span>个月，自<span class="underline">{{getDate(ruleForm.freeDateStartDate)}}</span>至<span class="underline">{{getDate(ruleForm.freeDateEndDate)}}</span>止。
        押金抵扣服务费用再赠送服务期限：
        <span class="underline">{{ruleForm.guaranteeMonths}}</span>个月，<span class="underline">{{getDate(ruleForm.guaranteeStartDate)}}</span>至<span class="underline">{{getDate(ruleForm.guaranteeEndDate)}}</span>止。赠送期内不收取服务费用，服务内容和服务标准不变。服务期限届满，甲方如需继续租赁使用，应与乙方重新签订服务协议。
      </p>
      <p style="">
        3、押金：人民币<span class="underline">{{ruleForm.guarantee}}</span>元。押金于本协议签订当日支付。押金的处理：
      </p>
      <p>
      <img  class="checked" v-if="ruleForm.guaranteeType ==='退押金'" src="@/assets/checked.png" alt="">
      <span v-else class="fk">□</span>服务期满，甲方按本协议及时交还设备，设备无人为损坏/故障等情形，经乙方审核通过后无息退还押金；
      </p>
      <p>
      <img  class="checked" v-if="ruleForm.guaranteeType ==='抵扣服务费'" src="@/assets/checked.png" alt="">
      <span v-else class="fk">□</span>押金用于抵扣服务费用。即服务期满乙方无需退还押金。
      </p>
      <p style="">
        4、服务费用：人民币<span class="underline">{{ruleForm.price}}</span>元。服务费应于本协议签订当日支付。如双方选择押金的处理方式为抵扣服务费用，则甲方应支付的服务费用为
        <span v-if="ruleForm.guaranteeType ==='抵扣服务费'" class="underline">{{ruleForm.guaranteePrice || '  '}}</span>
        <span v-if="ruleForm.guaranteeType ==='退押金'" class="underline">\</span>元，支付期限不变。
      </p>
    </div>
    <br />
    <br />
    <h3>
      第三条 使用说明及要求
    </h3>
    <p>
      为保障使用效果，甲方应敦促使用人严格按照本协议及附件使用方法、注意事项使用设备。
    </p>
    <p>
      1、使用次数及时长：每天2次，每次3分钟。
    </p>
    <p>
          特别说明：艾尔兴近视弱视综合治疗仪每天仅可使用两次，如当天遗忘使用，后续无法增加使用次数，亦不返还服务费用。
    </p>
    <p>
      2、为保障近视防控效果，甲方须保证使用人在服务期内：
    </p>
    <p>
      A、养成良好的用眼习惯。每次看电子屏幕（包括电脑、手机、电视、游戏机等）时间不超过20分钟，每天累计看电子屏幕不超过2小时(上课投影仪除外)；
    </p>
    <p>
      B、建议增加一定量的户外活动，增加眼睛接触阳光的机会与时长，不可直视太阳。
    </p>
    <p>
      C、定期检查视力情况，每三个月到医院检查眼轴长度和屈光度数，并将检查资料/结果/报告及时反馈给乙方建立专属的视力健康档案，以便乙方留存档案及提供相关服务。
    </p>
    <p>
      D、乙方专家会根据使用者实际情况作使用方案上的调整，甲方须听从乙方专家意见。
    </p>
    <p>
      E、使用人配合度须在80%以上（配合度=实际使用次数/应使用次数），治疗开始时间以第一次治疗时间为准。
    </p>
    <p style="">
      F、裸眼视力0.6以下或近视超过-1.00D的使用人，日常生活需要戴眼镜。
    </p>
    <p>
      3、服务期内，甲方须妥善保管设备，严格按照设备说明使用设备，以保障设备的正常运行。甲方在使用设备过程中有任何疑问，应及时与乙方沟通，解决问题。乙方已配备专业的客服人员，指引甲方正确使用设备，提示甲方定期检查设备状态。
    </p>
    <p>
      4、若设备发生故障或损坏，甲方需及时联系乙方进行维修或更换。经设备厂家检测后，若设备为自然故障，相关维修、更换费用由乙方承担。若非自然故障（包括但不限于人为损坏/使用不当导致等），维修费用、运费等相关费用由甲方承担。若设备毁损，甲方应按照设备价值全额赔付给乙方。
    </p>
    <p>
      5、甲方应当在服务期满之日起7天内将设备送至乙方业务点或者邮寄至乙方指定收货地址（相关运费由甲方承担）。
    </p>
    <h3>
      第四条 承诺与告知
    </h3>
    <p>1、甲方保证其提供给乙方的使用人眼部检查报告/结果真实、完整、有效，其已仔细阅读本协议及附件内容、已仔细阅读艾尔兴近视弱视综合治疗仪的使用方法、注意事项等，清楚艾尔兴近视弱视综合治疗仪的适用范围及禁忌症，保证使用人符合适用范围，可以使用艾尔兴近视弱视综合治疗仪。
    </p>
    <p>2、甲方保证其及使用人将严格按照本协议及附件、艾尔兴近视弱视综合治疗仪的使用方法、注意事项等使用艾尔兴近视弱视综合治疗仪。
    </p>
    <p>
      3、乙方已告知甲方青少年视力发育是个动态生长的过程，存在生理性增长，每个人使用艾尔兴近视弱视综合治疗仪的效果均不同，甲方对此清楚且无异议。
    </p>
    <p>
      4、乙方保证其提供给甲方的设备已取得权利人授权，是符合国家标准、经合法注册的合格产品。
    </p>
    <p style="">
      5、乙方承诺以下内容至少有一项可以达成：
    </p>
    <p>
      （1）正确使用艾尔兴近视弱视综合治疗仪前三个月内，控制眼轴长度不过度增长（以同一台眼生物测量仪或IOL-MASTER测量的眼轴长度为判定依据）。
    </p>
    <p>①患者年龄处于3-12岁，经过艾尔兴防控后，眼轴三个月增长不超过0.2mm。
    </p>
    <p>
      ②患者年龄处于13-18岁，使用艾尔兴防控后，眼轴三个月增长不超过0.15mm。
    </p>
    <p>
      （2）<span style="">正确使用艾尔兴近视弱视综合治疗仪前三个月内，</span>使用者散瞳验光后单眼等效球镜增长不超过-0.75D（上下波动50度属正常检测误差范围，以使用前后数据为依据）。
    </p>
    <h3>
      第五条 协议终止及退费
    </h3>
    <div>
      <p>
      1、无理由终止：即甲方于首次开通艾尔兴近视弱视综合治疗仪账户之日起30日内，可申请无理由全额退款，本协议终止。乙方自收到甲方退还的设备（经检测无损）后七个工作日内返还全部服务费用。
      </p>
      <p style="">2、不适用服务终止：即甲方在使用仪器期间，经公立三甲医院眼科专科医生诊断报告和检验报告诊断使用人不适合使用设备，甲方可向乙方申请终止协议。甲方申请经乙方审核通过后，双方确认服务协议终止，乙方自收到甲方退还的设备（经检测无损）后七个工作日内返还剩余服务费用【标准为：总服务费-扣除已经及正在使用的有偿使用套餐的服务费（按一个月期/三个月期/一年期）】，如未使用有偿服务期间剩余不足30天，乙方不予退费。
      </p>
      <p>
        3、不符承诺终止：即经公立三甲医院眼科专科医生诊断报告和检验报告诊断证明乙方就本协议第四条第5款的两项承诺中任一项未成就的，甲方可申请终止本协议。乙方在收到设备（经检测无损）七个工作日内返还剩余服务费用【标准为：总服务费-扣除已经及正在使用的有偿使用套餐的服务费（按一个月期/三个月期/一年期）】。
      </p>
      <p>4、礼品退回：甲方签订本协议时已获赠礼品<span class="underline" style="min-width: 75px">{{ruleForm.gift || ' '}}</span>。甲方清楚并同意如其在本协议生效后六个月内申请终止本协议的，甲方需于五个工作日内退还该礼品。逾期退还礼品，乙方有权直接从应退还的服务费用中扣减礼品金额
      </p>
      <p>
        5、本协议一经签订，除本协议约定事项外，任一方不得单方面无理由提前终止协议。甲方无正当理由提前终止协议的，服务费用不予退还。
      </p>
    </div>
    <h3>
      第六条 其他约定
    </h3>
    <p>1、本协议解释权归乙方。如有争议，双方协商解决，协商不成由乙方所在地人民法院裁决。违约方并需负担守约方支出的律师费、担保服务费等合理费用支出。
    </p>
    <p>2、本协议一式两份, 甲乙双方各执一份，自甲、乙双方签字盖章之日起生效，每份具有同等法律效力。
    </p>
    <p style="">
      3、本协议未尽事宜，由甲乙双方根据国家法律、法规及有关规定协商另行签订补充协议，双方共同遵守执行。
    </p>
    <p>
    </p>
    <div style="margin-top: 30px">
      <div style="display:inline-block;width:50%">
        <div style="height: 40px">
          <span>
             甲方签字：
          </span>
          <span>
            <img  v-if="ruleForm.signatureUrl" style="height: 40px;vertical-align: middle"
            crossOrigin="Anonymous"
            :src="ruleForm.signatureUrl + '?_now_='+new Date().getTime()" alt="">
          </span>
        </div>
        <div style="margin-top: 30px"  v-if="ruleForm.signatureUrl">
          日期: <span class="underline" v-if="ruleForm.signAt">{{getDate(ruleForm.signAt)}}</span>
        </div>
        <div style="margin-top: 30px" v-else>
          日期: ___年___月__日
        </div>
      </div>
      <div style="display:inline-block;width:50%;position: relative">
        <div style="height: 40px">
        乙方盖章：
        </div>
        <div style="margin-top: 30px"  v-if="ruleForm.createdAt">
          日期: <span class="underline" v-if="ruleForm.createdAt">{{getDate(ruleForm.createdAt)}}</span>
        </div>
        <div style="margin-top: 30px" v-else>
          日期: ___年___月__日
        </div>
        <img  style="position:absolute;width: 40%;top: 0px;right: 30%;" alt="Vue logo" src="@/assets/printsign.png">
      </div>
    </div>
    <p>
    </p>
  </div>
</div>
</template>
<script>
import moment from 'moment'
import oss from '../../utils/oss'
export default {
  props: ['ruleForm', 'print'],
  data() {
    return  {
      sts: {},
      dataUrl: ''
    }
  },
  beforeMount () {
  },
  methods: {
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async uploadBlobToOSS (imgBase64) {
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
      const id = new Date().getTime()
      const blob = this.dataURLtoBlob(imgBase64)
      try {
        const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
        this.uploadLoading = false
        this.$store.httpDispatch({})('saveAgreement', {
          _id: this.ruleForm._id,
          imageUrl: result.url
        }).then((res) => {
          const { code } = res.data
          this.uploadLoading = false
        }).catch((err) => {
          console.log(err.data)
          this.uploadLoading = false
        })
      } catch (err) {
        this.uploadLoading = false
        this.$notify({ title: '上传失败，请重新尝试', type: 'error' })
      }
    },
    getDate (createdAt) {
      if (!createdAt) {
        return '/'
      }
      return moment(createdAt).format('YYYY年MM月DD日')
    }
  }
}
</script>
<style lang="less" scoped>
@media (max-width: 767px) {
  .report {
    padding:  0 0!important;
  }

}
.report {
  padding: 50px 30px;
  .underline {
    // text-decoration:underline;
    font-weight: 800;
    border-bottom: 1px solid #777;
    display: inline-block;
    text-align: center;
    min-width:50px;
  }
  .checked {
     width: 12px;
     vertical-align: top;
     position: relative;
     top: 4px;
  }
  .bold {
    font-size: 22px;
    font-weight: 800;
  }
  .fk {
    font-size: 20px;
  }
  p {
    font-size: 13px;
    margin: 5px 0;
    line-height: 22px;
  }
  h3 {
    font-size: 15px;
    margin: 7px 0;
  }
  table td
  {
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid black;
  }
  footer {
    text-align: center;
    width: 100%;
  }
}
</style>
<template>
  <div class="Chat">
    <div v-if="user.type !== 'normal'"  @click="$router.push('/session')" class="chat-nav" >
      会话列表
    </div>
    <div @click="$router.push('/qrcode')" v-else class="chat-nav" >
      使用咨询
    </div>
    <div class="chat-panel">
      <div v-for="(log, index) in logs" :key="index" >
        <div v-if="log.type === 'text' || log.type === 'image'">
          <div class="chat-item chat-item-left" v-if="log.from === user.openid">
            <div>
              <span>{{users[log.from] && users[log.from].nickname}}</span>
              <div v-if="log.type === 'text'" class="chat-text bubble">
                {{ log.text }}
              </div>
              <div v-if="log.type === 'image'" class="chat-text chat-image bubble">
                <img :src="log.url" />
              </div>
            </div>
            <div class="chat-head-img">
              <img v-if="users[log.from] && users[log.from].type === 'normal'"  :src="users[log.from] && users[log.from].headimgurl">
              <img v-else-if="users[log.from] && users[log.from].type === 'doctor'" src="../../assets/doctor_headimg.png">
              <img v-else-if="users[log.from] && users[log.from].type === 'customer'" src="../../assets/customer_headimg.png">
            </div>
          </div>
          <div class="chat-item chat-item-right" v-else>
            <div class="chat-head-img">
              <img v-if="users[log.from] && users[log.from].type === 'normal'" :src="users[log.from] && users[log.from].headimgurl">
              <img v-else-if="users[log.from] && users[log.from].type === 'doctor'" src="../../assets/doctor_headimg.png">
              <img v-else-if="users[log.from] && users[log.from].type === 'customer'" src="../../assets/customer_headimg.png">
            </div>
            <div>
              <span>{{users[log.from] && users[log.from].nickname}}</span>
              <div v-if="log.type === 'text'" class="chat-text bubble">
                {{ log.text }}
              </div>
              <div v-if="log.type === 'image'" class="chat-text chat-image bubble">
                <img :src="log.url" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="log.type === 'info' && user.type !== 'normal'">
          <div class="chat-item chat-item-right">
            <div class="chat-head-img">
              <img :src="users[log.from] && users[log.from].headimgurl">
            </div>
            <div>
              <span>{{users[log.from] && users[log.from].nickname}}</span>
              <div class="chat-text bubble">
                {{ log.text }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="log.type === 'alert'">
          <div class="chat-item chat-alert">
            <div class="chat-text bubble">
              {{ log.text }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="chat-footer">
      <div class="chat-input">
        <button @click="dialogShow = !dialogShow" class="more-button"><img src="../../assets/more_btn.jpeg" alt=""></button>
        <input @keyup.enter="sendMessage('text')" v-model="text" value="" />
        <button @click="sendMessage('text')" class="send-button">发送</button>
      </div>
    </div>
    <mt-popup
      v-model="dialogShow"
      position="bottom">
      <div class="popup-grids chat-btns">
        <div @click="chooseImage" class="chat-grid">

          <img src="../../assets/image_btn.jpg" alt="">
          <p>发送图片</p>
        </div>
        <div v-if="user && user.type === 'customer'" @click="openDialog('doctor')" href="javascript:" class="chat-grid">
          <img src="../../assets/doctor_headimg.png" alt="">
          <p>转接医生</p>
        </div>
        <div v-if="user && user.type === 'customer'" @click="openDialog('customer')" href="javascript:" class="chat-grid">
          <img src="../../assets/customer_headimg.png" alt="">
          <p>转接客服</p>
        </div>
        <mt-button @click="closeSession" class="chat-grid" type="danger">结束会话</mt-button>

      </div>
    </mt-popup>
    <mt-popup
      v-model="enabled"
      position="bottom">
     <CustomerList :getSession="getSessionById" :doctorId="session.doctorId" :list="list" :type="enabledType" :sendMessage="sendMessage" :sessionId="sessionId" :close="close"/>
    </mt-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
import { Indicator, Toast } from 'mint-ui';
import CustomerList from '../components/CustomerList.vue'
export default {
  name: 'Chat',
  data() {
    return {
      src: '',
      packageMap: {
        experience: '体验',
        halfyear: '半年',
        oneyear: '一年',
        fiveyear: '五年'
      },
      localData: '',
      session: {},
      sessionId: '',
      text: '',
      url: '',
      logs: [],
      users: {},
      dialogShow: false,
      chatTimer: null,
      enabled: false,
      enabledType: 'doctor',
      list: []
    }
  },
  components: {
    CustomerList
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    if (this.$route.query.sessionId) {
      this.getSessionById()
    } else {
      this.getSessionByFrom()
    }
  },
  destroyed() {
    clearTimeout(this.chatTimer)
  },

  methods: {
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY年MM月DD日')
    },
    close () {
      this.enabled = !this.enabled
    },
    closeSession () {
      this.$store.httpDispatch({})('closeSession', {
        sessionId: this.sessionId
      }).then((res) => {
        const { code, data, msg } = res.data
        console.log(res.data)
        if (code === 0) {
          window.alert('本次咨询已结束')
          this.enabled = false
        } else {
          window.alert(msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    openDialog (type) {
      this.enabled = true
      this.dialogShow = false
      this.enabledType = type
      this.getUsers(type)
    },
    getUsers (type) {
      if (type === 'doctor') {
        this.getDoctors()
      } else if (type === 'customer') {
        this.getCustomers()
      }
    },
    getCustomers () {
      this.$store.httpDispatch({})('getCustomers')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.users.filter((u) => u.openid !== this.user.openid)
        }
      })
    },
    getDoctors () {
      this.$store.httpDispatch({})('getDoctors')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.users.filter((u) => u.openid !== this.user.openid)
        }
      })
    },
    // wx upload
    // wx upload image
    chooseImage () {
      const that = this
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          let image = {}
          image.url = localIds[0]
          wx.uploadImage({
            localId: localIds[0], // 图片的localID
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
              var serverId = res.serverId; // 返回图片的服务器端ID
              that.serverId = serverId
              that.sendMessage('image')
              that.enabled = false
            }
          });
        }
      });
    },
    getSessionByFrom () {
      this.$store.httpDispatch({})('getSessionByFrom')
      .then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          const session = data.session
          const users = data.users
          this.users = users
          if (session) {
            this.session = session
            this.sessionId = session._id
            this.subscribeMessage()
          } else {
            this.$router.push('/session')
          }
        }
      }).catch((err) => {
        console.log(err)
        alert('获取会话失败，请检查网络后刷新')
      })
    },
    getSessionById () {
      this.$store.httpDispatch({})('getSessionById', {
        sessionId: this.$route.query.sessionId || this.sessionId
      })
      .then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          const session = data.session
          const users = data.users
          if (session) {
            this.session = session
            this.sessionId = session._id
            this.users = users
          }
        }
        this.subscribeMessage()
      }).catch((err) => {
        console.log(err)
        alert('获取会话失败，请检查网络后刷新')
      })
    },
    subscribeMessage () {
      const query = this.$route.query
      if(this.$route.path !== '/chat') {
        return false
      }
      this.$store.httpDispatch({})('getMessage', {
        sessionId: this.sessionId
      })
      .then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.logs = data.messages
          this.logs.forEach((log) => {
            if (log.type === 'alert' && log.state === 'created' && log.from !== this.user.openid) {
              window.alert(log.text)
            }
          })
          if (this.logs.length === 0 && this.user.type === 'normal') {
            this.sendMessage('info',
              `用户名：${this.user.nickname}, 手机号：${this.user.mobile || ''}, 套餐：${this.packageMap[this.user.package] || ''}, 开始时间：${this.getDate(this.user.startDate)}, 结束时间：${this.getDate(this.user.endDate)}`)
          }
        }
        this.chatTimer = setTimeout(() => {
          this.subscribeMessage()
        }, 2000)
      }).catch((err) => {
        console.log(err)
        const { code, msg } = err.data
        if (code === 400111) {
          return alert('此会话已被转接，请退出聊天')
        } else {
          this.chatTimer = setTimeout(() => {
            this.subscribeMessage()
          }, 2000)
        }
      })
    },
    sendMessage (type, alertMsg) {
      const query = this.$route.query
      if (type === 'text' && !this.text) {
        return
      }
      const msg = {
        text: type === 'text' ? this.text : '',
        serverId: type === 'image' ? this.serverId : '',
        type: type,
        from: this.user.openid,
        sessionId: this.sessionId,
        createdAt: new Date()
      }
      if (alertMsg) {
        msg.text = alertMsg
      }
      this.$store.httpDispatch({})('sendMessage', msg)
        .then((res) => {
          this.sessionId = res.data.data.sessionId
        })
      this.text = ''
      const logs = Object.assign({}, this.logs)
      this.logs.push(msg)
      setTimeout(() =>  window.scrollTo(0, document.body.scrollHeight), 0)
    }
  }
}
</script>

<style lang="less">
.Chat {
  min-height: 100vh;
  width: 100vw;
  background : #eee;
}

.chat-nav {
  position: fixed;
  top: 30vh;
  right: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  line-height: 70px;
  box-sizing: border-box;
  z-index: 1;
  background: #007FC2;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
}
.chat-panel {
  padding-top: 20px;
  padding-bottom: 100px;
  .chat-item-left {
    text-align: right;
    justify-content: flex-end;
    .chat-head-img {
      padding-left: 15px;
    }
    .bubble:after{
      content:'';
      position:absolute;
      top:11px;
      width:0;height:0;
      border-width:10px;
      border-style:solid;
      border-color:transparent;
      color: #fff;
      left:100%;
      border-left-width:16px;
      border-left-color:currentColor;
    }
  }
  .chat-item-right {
    text-align: left;
    justify-content: flex-start;
    .chat-head-img {
      padding-right: 15px;
    }
    span {
      text-align: right;
    }
    .bubble:after{
      content:'';
      position:absolute;
      top:11px;
      width:0;height:0;
      border-width:10px;
      border-style:solid;
      border-color:transparent;
      color: #fff;
      right:100%;
      border-right-width:16px;
      border-right-color:currentColor;
    }
  }
  .chat-alert {
    padding: 0 20px!important;
    margin: 5px 0;
    div.chat-text {
      background: rgba(255,255,255, 0.5)!important;
      font-size: 12px;
      text-align: center;
      width: 100%;
    }
  }
  .chat-item {
    display: flex;
    padding: 10px 5px;
    .chat-head-img {
      width: 60px;
      img {
        border: 2px solid #fff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }
    }
    .chat-image {
      img {
        max-width: 90%
      }
    }
    .chat-text {
      background: #fff;
      height: inherit;
      text-align: left;
      border-radius: 5px;
      padding: 10px 7px;
      box-sizing: border-box;
      font-size: 14px;
      min-height: 40px;
      word-break:break-all;
    }
    .bubble {
      position: relative;
    }
  }
}
.popup-grids {
  padding: 20px 20px;
  width: 100vw;
  text-align: left;
  height: 200px;
  .chat-grid {
    text-align: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    padding: 10px 10px;
    border: 1px solid #eee;
    box-sizing: border-box;
    margin-left: 10px;
    margin-bottom: 10px;
    p {
      margin: 0 0;
    }
    img {
      width: 50px;
    }
  }
  .chat-grid:last-child {
    font-size: 16px;
    vertical-align: top;
  }
}
.chat-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;
  background: #eee;
  .chat-input {
    border: 1px solid #ccc;
    background: #F7F7F7;
    width: 100vw;
    height: 60px;
    padding: 10px 10px;
    box-sizing: border-box;
    display: flex;
    input {
      width: 100%;
      background: #fff;
      font-size: 20px;
      height: 40px;
      box-sizing: border-box;
      border: #ccc 1px solid;
      margin-right: 3px;
      margin-left: 3px;
    }
    .send-button {
      border: #ccc 1px solid;
      background: #fff;
      height: 40px;
      width: 100px;
      box-sizing: border-box;
    }
    .more-button {
      box-sizing: border-box;
      height: 40px;
      width: 40px;
      border: 0;
      background: none;
      padding: 0 0;
      img {
        margin-top: 5px;
        width: 100%;
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>

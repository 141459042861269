<template>
  <div >
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import moment from 'moment'
export default {
  components: {
  },
  name: 'home-page',
  data() {
    return {
      loaded: false,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
  },
  updated: function () {
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    getUserByWX: function (){
      const authed = this.getQueryVariable('authed')
      if (!authed) {
        return window.location.href = `https://payapp.weixin.qq.com/appauth/authindex?mch_id=1591313011&appid=wx2896d170d50383b5&redirect_uri=https%3A%2F%2Fwx.my-eye.com.cn%2F%23%2Fauth%3F_id=${this.$route.query._id}&authed=true&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`
      }
      const code = this.getQueryVariable('code')
      this.$store.httpDispatch({})('getAuthToken', {
        code,
        openid: this.user.openid
      }).then((res) => {
        this.closeLoad()
        const { code, data } = res.data
        if (code === 0) {
          console.log(data)
          // push to  agreement
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        this.closeLoad()
        console.log(err)
      })

    },
  }
}
</script>
<style lang="less">
  .hidden {
    display: none;
  }
  #canvas_body {
    position: fixed;
    left: 0;
    .mint-button {
      width: 30%;
      margin-left: 2%;
      margin-top: 100px;
    }
  }
  #canvas_body canvas {
    display: block;
  }
  .consent-body {
    background: #fff;
  }
  .consent-page {
    padding: 30px 20px;
    .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .section {
      text-indent: 2em;
    }
    .signature-button {
      margin-bottom: 20px;
      text-align: left;
    }
    .signature-img {
      width: 50%;
      height: 50px;
    }
    .signature-label {
      width: 45%;
    }
    .signature-box {
      border-bottom: 2px solid #333;
      display: inline-block;
      margin-left: 10px;
      width: 50%;
      vertical-align: bottom;
    }
  }
  .errorCanvas {
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateY(40%);
  }
  .info-page {
    margin: 20px 50px;
    background: #fff;
    h1 {
      text-decoration: underline;
      font-size: 15px;
      margin: 0 2px;
    }
    .print_header p {
      margin-bottom: 2px;
    }
  .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    .checked {
      span {
        font-size: 30px;
      }
      img {
        position: relative;
        top: 2px;
        width: 14px;
      }
    }
    .section-box {
      vertical-align: top;
    }
    .section {
      border-top: 3px solid #333;
      padding: 5px 10px;
      margin-bottom: 50px;
      text-align: left;
      table {
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
      .small-input {
        width: 15px;
        height: 15px;
        padding: 0 0;
        margin: 0 0;
        font-weight: 800;
        display: inline-block;
        line-height: 20px;
        text-align: center;
        margin: 0 10px;
        border: 1px solid #333!important;
      }
      .input-box {
        margin-bottom: 20px;
        font-size: 13px;
        label {
          display: inline-block;
        }
      }
      .inline-div {
        display: inline-block;
        width: 50px;
        vertical-align: bottom;
      }
      .border-input {
        border: 1.5px solid #333!important;
        display: inline-block;
        width: 100px;
      }
      .inline-box {
        width: 135px;;
        display: inline-block;
      }
      .input-box .input-div {
        border: 0;
        width: auto;
        border-bottom: 1px solid #333;
        margin-top: 20px;
        word-break: break-all;
      }
      .eyes-table {
        margin-top: 15px;
        td, th {
          height: 40px;
        }
      }
      .input-name {
        font-size: 17px;margin-right: 30px;
        font-weight: 500;;
      }
      .section-titile {
        background: #D0CECE;
        text-align: right;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        padding: 0 10px;
        box-sizing: border-box;
        label {
          font-size: 17px;
          float: left;
          font-weight: 800;
        }
      }
    }
  }
</style>

<template>
  <div class="payment-list" >
    <div class="payment-titile">
      <div style="width: 65px;margin-right: 10px;">序号</div>
      <div style="flex: 1">支付时间</div>
    </div>
    <div @click="activeIndex = index" class="payment-item" v-for="(payment, index) in list" :key="payment._id">
      <div class="payment-head">
        <div style="width: 65px">{{index + 1}}<div class="headerDivider"></div></div>
        <div style="flex: 1">
          <span>{{getDate(payment.createdAt)}}</span>
          <img v-if="activeIndex === index" class="icon-bottom" src="../../assets/icon_bottom.png" alt="">
          <img v-else class="icon-right" src="../../assets/icon_right.png" alt="">
        </div>
      </div>
      <div class="payment-table" v-if="activeIndex === index">
        <table border="1">
          <tbody>
            <tr>
                <td>支付项目</td>
                <td style="flex: 1">{{payment.description}}</td>
            </tr>
            <tr>
                <td>状态</td>
                <td style="flex: 1">{{stateMap[payment.state]}}</td>
            </tr>
            <tr>
                <td>费用</td>
                <td style="flex: 1">{{payment.amount.total}}元</td>
            </tr>
            <tr>
                <td>订单号</td>
                <td style="flex: 1">{{payment._id}}</td>
            </tr>
            <tr v-if="payment.type === 'DEPOSIT'">
                <td>操作</td>
                <td style="flex: 1; padding: 10px 0">
                  <mt-button @click="refund(payment._id)" style="height: 35px; width: 130px;background: #007EC5;" class="user-btn" type="primary">退押金</mt-button></td>
            </tr>
          </tbody>
        </table>
        <mt-popup
          v-model="popupVisible"
          position="bottom">
          <div class="refund-div" style="width: 100vw;height: 90vh">
            <h2>退款申请</h2>
            <mt-field class="required" label="申请原因" v-model="form.remark"></mt-field>
            <mt-field label="快递单号" v-model="form.express_code"></mt-field>
            <mt-button @click="submit" style="height: 40px;margin-top: 20px; width: 200px;background: #007EC5;" class="user-btn" type="primary">提交</mt-button>
          </div>
        </mt-popup>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'PaymentList',
  data() {
    return {
      popupVisible: false,
      form: {
        remark: '',
        express_code: ''
      },
      stateMap: {
        refunded: '退款成功',
        refunding: '退款中',
        settled: '充值成功',
        created: '未支付',
        paid: '已支付',
        refunded: '已退款'
      },
      activeIndex: null,
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getPayments()
  },
  methods: {
    getDate(time) {
      if (!time) {
        return ''
      }
      return moment(time).format('YYYY-MM-DD HH:mm')
    },
    refund (paymentId) {
      this.paymentId = paymentId
      this.popupVisible = true
    },
    submit: function (){
      this.$store.httpDispatch({})('refundByUser', {
        id: this.paymentId,
        remark: this.form.remark,
        express_code: this.form.express_code
      }).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          console.log(data)
          this.getPayments()
          this.popupVisible = false
          // push to  agreement
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getPayments () {
      this.$store.httpDispatch({})('getPayments')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.list
        }
      })
    }
  }
}

</script>

<style lang="less">
.refund-div {
  .mint-cell-value {
    border-bottom: 1px solid #ccc;
  }
}
.payment-list {
  padding: 20px 20px 0;
  box-sizing: border-box;
  .payment-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .payment-titile {
    background: none;
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    div {
      background: #007EC5;
      border-radius: 5px;
    }
  }
  .payment-item {
    margin-bottom: 10px;
    .payment-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; }
    }
  }
  .payment-head {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #ADD6E9;
      border-right:1px solid #ADD6E9;
      height: 18px;
      position:absolute;
      left: 60px;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 20px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
</style>


<template>
  <div class="customer-list">
    <mt-cell :class="doctorId === user.openid ? 'active' : '111'" :title="user.nickname" @click.native="putSession(user.openid, user.nickname)"
      v-for="user in list" :key="user._id">
      <img slot="icon" :src="user.headimgurl" >
    </mt-cell>
    <div style="padding: 20px">
      <mt-button type="default" @click="close">取消转接</mt-button>
      <mt-button v-if="type === 'doctor'" type="danger" @click="clearDoctor">移除医生</mt-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex'
export default {
  name: 'CustomerList',
  data() {
    return {
      loadType: ''
    }
  },
  props: [
    'getSession',
    'doctorId',
    'sessionId',
    'sendMessage',
    'type',
    'list',
    'close'
  ],
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
  },
  methods: {
    clearDoctor () {
      this.$store.httpDispatch({})('putSessionDoctor', {
        sessionId: this.sessionId,
        to: ''
      }).then((res) => {
        if (res.data.code === 0) {
          this.sendMessage('alert', `已将医生移出会话。`)
          this.getSession()
          this.close()
        }
      })
    },
    putSession(to, nickname) {
      if (this.type === 'doctor') {
        this.putSessionDoctor(to, nickname)
      } else if (this.type === 'customer') {
        this.putSessionCustomer(to, nickname)
      }
    },
    putSessionDoctor (to, nickname) {
      this.$store.httpDispatch({})('putSessionDoctor', {
        sessionId: this.sessionId,
        to: to
      }).then((res) => {
        if (res.data.code === 0) {
          this.getSession()
          this.sendMessage('alert', `您的咨询已经转给${nickname}医生，医生最迟将在2小时内为您解答，请留意公众号消息。`)
          this.close()
        }
      })
    },
    putSessionCustomer (to, nickname) {
      this.$store.httpDispatch({})('putSessionCustomer', {
        sessionId: this.sessionId,
        to: to
      }).then((res) => {
        if (res.data.code === 0) {
          this.getSession()
          this.sendMessage('alert', `您的咨询已经转给${nickname}，${nickname}将继续为您服务。`)
          this.close()
          window.alert('转接成功，请关闭聊天')
        }
      })
    }
  }
}
</script>

<style lang="less">
.customer-list {
  box-sizing: border-box;
  padding-top: 50px;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  text-align: left;
  img {
    width: 50px;
  }
  .mint-cell-wrapper {
    border-bottom: 1px solid #eee
  }
  .mint-cell-wrapper:last-child {
    text-align: center;
  }
  .mint-button {
    width: 100%;
    margin-bottom: 20px;
  }
  .active {
    background: #198ACB;
    color: #fff;
  }
}
</style>

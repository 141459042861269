import OSS from 'ali-oss'
import axios from 'axios'
const mapOssConfig = (Credentials) => {
  return {
    region: 'oss-cn-shenzhen',
    accessKeyId: Credentials.AccessKeyId,
    accessKeySecret: Credentials.AccessKeySecret,
    stsToken: Credentials.SecurityToken,
    bucket: 'eyerising-upload'
  }
}

async function getSTS () {
  let result = null
  try {
    result = await axios.get('/api/oss/sts')
  } catch (err) {
    console.log(err)
  }
  return result && result.data
}

const putBlob = (credentials) => async (blob, fileName, options) => {
  const ossConfig = mapOssConfig(credentials)
  let result = null
  const client = new OSS({ ...ossConfig })
  try {
    result = await client.put(fileName, blob, { type: 'text/plain' })
  } catch (e) {
    console.log(e)
    throw e
  }
  return result
}

export default {
  putBlob,
  getSTS
}

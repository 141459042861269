<template>
  <div >
    <div :style="{'top': top + 'px'}" id="canvas_body" v-show="activeKey">
      <canvas :width="width" :height="height" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd" id="canvas"></canvas>
      <mt-button @click="cancel" id="saveCanvas">取消Cancel</mt-button>
      <mt-button @click="clearRect" id="clearCanvas">清除</mt-button>
      <mt-button type="primary" @click="saveImg" :disabled="uploadLoading" id="saveCanvas">保存</mt-button>
    </div>
    <div v-if="!loaded">
    </div>
    <div v-else v-show="!activeKey" class="consent-body">
      <div class="consent-page">
          <div v-if="!info.imageUrl">
            <HTMLAgreement v-if="(info.reportType == 'html')" :model="info.model" :ruleForm="info" :print="true"/>
            <AgreementReport v-if="(!info.reportType || info.reportType == 'agreement') && (info.agreementType === '普通' || !info.agreementType)" :ruleForm="info" :print="true"/>
            <AgreementM1S1 v-if="(!info.reportType || info.reportType == 'agreement') && info.agreementType === '买一送一'" :ruleForm="info" :print="true"/>
            <AgreementFQ v-if="(!info.reportType || info.reportType == 'agreement') && info.agreementType === '分期付款'" :ruleForm="info" :print="true"/>

            <NewAgreementReport v-if="info.reportType === 'saleAgreement' && info.agreementType !== '租用三个月&一年'" :ruleForm="info" :print="true"/>
            <NewZAgreementReport v-if="info.reportType === 'saleAgreement' && info.agreementType === '租用三个月&一年'" :ruleForm="info" :print="true"/>


            <CommitmentReport v-if="info.reportType === 'commitment' && (info.commitmentType === '普通' || !info.commitmentType)" :ruleForm="info" />
            <ReceiptReport v-if="info.reportType === 'receipt'" :ruleForm="info" />
            <CheckCommitentReport v-if="info.reportType === 'commitment' && info.commitmentType === '眼底检查承诺'" :ruleForm="info" />
            <LeaseCommitment v-if="info.reportType === 'commitment' && info.commitmentType === '便携版仪器借用申请书' " :ruleForm="info" />
          </div>
          <img  style="width:100%;margin-bottom: 50px;" v-else :src="info.imageUrl" >
          <section @click="startSign('signatureUrl')" class="signature-button" v-if="!info.signatureUrl && info.reportType !== 'receipt'">
            <mt-button class="signature-label">监护人签名</mt-button>
            <div class="signature-box">
              <img class="signature-img" v-if="signatureUrl" :src="signatureUrl" alt="">
            </div>
          </section>
          <mt-button v-if="!info.signatureUrl && info.reportType !== 'receipt'" class="signature-label" type="primary" :disabled="uploadLoading" @click="submitSign">提交</mt-button>
        </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import moment from 'moment'
import oss from '../../utils/oss'
import NewAgreementReport from '../../admin/components/NewAgreementReport.vue'
import NewZAgreementReport from '../../admin/components/NewZAgreementReport.vue'
import AgreementReport from '../../admin/components/AgreementReport.vue'
import HTMLAgreement from '../../admin/components/HTMLAgreement.vue'
import AgreementM1S1 from '../../admin/components/AgreementM1S1.vue'
import AgreementFQ from '../../admin/components/AgreementFQ.vue'
import CommitmentReport from '../../admin/components/CommitmentReport.vue'
import ReceiptReport from '../../admin/components/ReceiptReport.vue'
import CheckCommitentReport from '../../admin/components/CheckCommitentReport.vue'
import LeaseCommitment from '../../admin/components/LeaseCommitment.vue'
export default {
  components: {
    NewZAgreementReport,
    NewAgreementReport,
    AgreementM1S1,
    AgreementFQ,
    AgreementReport: AgreementReport,
    CommitmentReport,
    ReceiptReport,
    HTMLAgreement,
    CheckCommitentReport,
    LeaseCommitment
  },
  name: 'home-page',
  data() {
    return {
      loaded: false,
      info: {},
      top: 100,
      width: 0,
      height: 0,
      activeKey: '',
      signatureUrl: '',
      fsignatureUrl: '',
      msignatureUrl: '',
      uploadLoading: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.width = window.screen.width
    this.height = window.screen.height  * 0.3
    document.getElementById('canvas').addEventListener('touchmove', function (e) {
      e.preventDefault()
    }, { passive: false })
    this.getUserInfo()
  },
  updated: function () {
  },
  methods: {
    startSign (type) {
      this.activeKey = type
      this.lineCanvas()
    },
    getDate (createdAt) {
      if (!createdAt) return ''
      return moment(createdAt).format('YYYY/MM/DD')
    },
    lineCanvas(obj) {
      this.lineWidth = 1;
      this.color = '#000';
      this.background = '#fff';
      this.canvas = document.querySelector('#canvas')
      if (!(this.canvas.getContext && this.canvas.getContext('2d'))) {
        Toast({ message: '对不起，当前浏览器暂不支持此功能！',
          duration: 3000})
        return
      }
      this.cxt = this.canvas.getContext('2d')
      this.cxt.fillStyle = this.background
      this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.height)
      this.cxt.strokeStyle = this.color
      this.cxt.lineWidth = this.lineWidth
      this.cxt.lineCap = 'round'
      this.cxt.lineJoin = 'round'
      this.cxt.shadowBlur = 1
      this.cxt.shadowColor = '#000'
    },
    handleTouchMove (e) {
      this.cxt.lineTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY - this.top)
      this.cxt.stroke();
    },
    handleTouchStart (e) {
      this.cxt.beginPath();
      this.cxt.moveTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY - this.top);
    },
    handleTouchEnd (e) {
      this.cxt.closePath();
    },
    clearRect () {
      this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.cxt.fillStyle = '#fff'
      this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },
    cancel () {
      this.activeKey = ''
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 0)
    },
    async saveImg () {
      var imgBase64 = this.canvas.toDataURL();
      this.imgBase64 = imgBase64
      await this.uploadBlobToOSS()
      this.activeKey = ''
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 0)
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async uploadBlobToOSS () {
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        Toast({ message: '上传失败，请重新尝试',
          duration: 3000})
      }
      const id = new Date().getTime()
      const blob = this.dataURLtoBlob(this.imgBase64)
      try {
        const result = await oss.putBlob(this.sts.data)(blob, `eyerising-upload/${id}.jpg`)
        this[this.activeKey] = result.url
        this.uploadLoading = false
      } catch (err) {
        this.uploadLoading = false
        Toast({ message: '上传失败，请重新尝试',
          duration: 3000 })
      }
    },
    async getUserInfo () {
      const _id = this.$route.query._id
      let result = null
      try {
        result = await this.$store.httpDispatch({})('getAgreement', {
          _id
        })
        console.log(result)

        const { code, data } = result.data
        console.log(data)
        if (code === 0) {
          this.loaded = true
          this.info = data.agreement
        }
      } catch (err) {
       return alert(err.data.msg)
      }
    },
    async submitSign () {
      const body = {
        _id: this.$route.query._id,
        signatureUrl: this.signatureUrl,
      }
      if (!this.signatureUrl) {
        return Toast({
          message: '请先签名后提交',
          duration: 3000
        })
      }
      this.uploadLoading = true
      this.$store.httpDispatch({})('signAgreement', body).then((res) => {
        const { code } = res.data
        this.uploadLoading = false
        if (code === 0) {
          setTimeout(() => {
            window.location.reload()
          }, 3000)
          return Toast({
            message: '提交成功',
            duration: 3000
          })
        }
        if (code === 404) {
          return Toast({
            message: '找不到相关数据，请联系客服',
            duration: 3000
          })
        }
        if (code === 401) {
          Toast({
            message: '您已经签过名了哦，不可重复签名。',
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err.data)
        this.uploadLoading = false
        return Toast({
          message: err.data.msg,
          duration: 3000
        })
      })
    }
  }
}
</script>
<style lang="less">
  img,
  .html-report,
  .report{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events:none;
  }
  
  .editor-content-view {
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    margin-top: 20px;
    overflow-x: auto;
  }

  .editor-content-view p,
  .editor-content-view li {
    white-space: pre-wrap; /* 保留空格 */
  }

  .editor-content-view blockquote {
    border-left: 8px solid #d0e5f2;
    padding: 10px 10px;
    margin: 10px 0;
    background-color: #f1f1f1;
  }

  .editor-content-view code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
    border-radius: 3px;
  }
  .editor-content-view pre>code {
    display: block;
    padding: 10px;
  }

  .editor-content-view table {
    border-collapse: collapse;
  }
  .editor-content-view td,
  .editor-content-view th {
    border: 1px solid #ccc;
    min-width: 50px;
    height: 20px;
  }
  .editor-content-view th {
    background-color: #f1f1f1;
  }

  .editor-content-view ul,
  .editor-content-view ol {
    padding-left: 20px;
  }

  .editor-content-view input[type="checkbox"] {
    margin-right: 5px;
  }

  .hidden {
    display: none;
  }
  #canvas_body {
    position: fixed;
    left: 0;
    .mint-button {
      width: 30%;
      margin-left: 2%;
      margin-top: 100px;
    }
  }
  #canvas_body canvas {
    display: block;
  }
  .consent-body {
    background: #fff;
  }
  .consent-page {
    padding: 30px 20px;
    .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .section {
      text-indent: 2em;
    }
    .signature-button {
      margin-bottom: 20px;
      text-align: left;
    }
    .signature-img {
      width: 50%;
      height: 50px;
    }
    .signature-label {
      width: 45%;
    }
    .signature-box {
      border-bottom: 2px solid #333;
      display: inline-block;
      margin-left: 10px;
      width: 50%;
      vertical-align: bottom;
    }
  }
  .errorCanvas {
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateY(40%);
  }
  .info-page {
    margin: 20px 50px;
    background: #fff;
    h1 {
      text-decoration: underline;
      font-size: 15px;
      margin: 0 2px;
    }
    .print_header p {
      margin-bottom: 2px;
    }
  .sloder {
      width: 100%;
      border-bottom: 2px solid #333;
    }
    .checked {
      span {
        font-size: 30px;
      }
      img {
        position: relative;
        top: 2px;
        width: 14px;
      }
    }
    .section-box {
      vertical-align: top;
    }
    .section {
      border-top: 3px solid #333;
      padding: 5px 10px;
      margin-bottom: 50px;
      text-align: left;
      table {
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
      .small-input {
        width: 15px;
        height: 15px;
        padding: 0 0;
        margin: 0 0;
        font-weight: 800;
        display: inline-block;
        line-height: 20px;
        text-align: center;
        margin: 0 10px;
        border: 1px solid #333!important;
      }
      .input-box {
        margin-bottom: 20px;
        font-size: 13px;
        label {
          display: inline-block;
        }
      }
      .inline-div {
        display: inline-block;
        width: 50px;
        vertical-align: bottom;
      }
      .border-input {
        border: 1.5px solid #333!important;
        display: inline-block;
        width: 100px;
      }
      .inline-box {
        width: 135px;;
        display: inline-block;
      }
      .input-box .input-div {
        border: 0;
        width: auto;
        border-bottom: 1px solid #333;
        margin-top: 20px;
        word-break: break-all;
      }
      .eyes-table {
        margin-top: 15px;
        td, th {
          height: 40px;
        }
      }
      .input-name {
        font-size: 17px;margin-right: 30px;
        font-weight: 500;;
      }
      .section-titile {
        background: #D0CECE;
        text-align: right;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        line-height: 50px;
        padding: 0 10px;
        box-sizing: border-box;
        label {
          font-size: 17px;
          float: left;
          font-weight: 800;
        }
      }
    }
  }
</style>

<template>
  <div class="visit-list" >
    <div class="visit-titile">
      <div style="width: 65px;margin-right: 10px;">序号</div>
      <div style="flex: 1">时间</div>
    </div>
    <div @click="activeIndex = index" class="visit-item" v-for="(visit, index) in list" :key="visit._id">
      <div class="visit-head">
        <div style="width: 65px">{{index + 1}}<div class="headerDivider"></div></div>
        <div style="flex: 1">
          <span>{{getDate(visit.recordedAt)}}</span>
          <img v-if="activeIndex === index" class="icon-bottom" src="../../assets/icon_bottom.png" alt="">
          <img v-else class="icon-right" src="../../assets/icon_right.png" alt="">
        </div>
      </div>
      <div class="visit-table" v-if="activeIndex === index">
        <table border="1">
          <tbody>
            <tr>
                <td rowspan="2">眼轴</td>
                <td>左眼</td>
                <td style="flex: 1">{{visit.axis_os || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.axis_od || '无'}}</td>
            </tr>
            <tr>
                <td rowspan="2">屈光度</td>
                <td>左眼</td>
                <td>{{visit.diopter_os_sph || '无'}}/{{visit.diopter_os_cyl || '无'}}/{{visit.diopter_os_axis || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.diopter_od_sph || '无'}}/{{visit.diopter_od_cyl || '无'}}/{{visit.diopter_od_axis || '无'}}</td>
            </tr>
            <tr>
                <td rowspan="2">矫正视力</td>
                <td>左眼</td>
                <td>{{visit.vision_os || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.vision_od || '无'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <mt-button class="visit-btn" @click="$router.push('/chart')" type="primary">视力数据曲线图</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'VisitList',
  data() {
    return {
      activeIndex: null,
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getVisits()
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) {
        return ''
      }
      return moment(createdAt).format('YYYY-MM-DD')
    },
    getVisits () {
      this.$store.httpDispatch({})('getVisits')
      .then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.list = res.data.data.visits
        }
      })
    }
  }
}
</script>

<style lang="less">
.visit-list {
  padding-top: 50px;
  padding: 50px 20px 0;
  box-sizing: border-box;
  .visit-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .visit-titile {
    background: none;
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    div {
      background: #007EC5;
      border-radius: 5px;
    }
  }
  .visit-item {
    margin-bottom: 10px;
    .visit-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; }
    }
  }
  .visit-head {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #ADD6E9;
      border-right:1px solid #ADD6E9;
      height: 18px;
      position:absolute;
      left: 60px;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 20px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
</style>
